export const accommodationTypesBradesco = [
  { label: 'Apartamento', value: 'Apartamento' },
  { label: 'Enfermaria', value: 'Enfermaria' },
  { label: 'Odonto', value: 'Odonto' },
  { label: 'Quarto Privativo', value: 'Quarto Privativo' },
  { label: 'Multiplo 2', value: 'Multiplo 2' },
  { label: 'Multiplo 3', value: 'Multiplo 3' },
  { label: 'Multiplo 4', value: 'Multiplo 4' },
  { label: 'Multiplo 6', value: 'Multiplo 6' },
  { label: 'Multiplo 8', value: 'Multiplo 8' },
  { label: 'Multiplo 10', value: 'Multiplo 10' },
]

export const accommodationDentalTypesBradesco = [{ label: 'Não se Aplica', value: 'Não se Aplica' }]
