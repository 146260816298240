import { Entity, GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'

import type { EntityType } from '../..'
import { getRequiredEntityFields } from '../helper'

export const getRequiredFieldsBradesco = (orderData?: GetOrderInformationBody) => {
  const dynamicFormValidator: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = []

  if (orderData?.quotation?.type === 'COMPANY') {
    dynamicFormValidator.push(
      getRequiredEntityFields(orderData?.company, [
        'document',
        'phone',
        'mail',
        'address.zipCode',
        'address.number',
        'externalLegalRepresentative.name',
        'externalLegalRepresentative.role',
        'externalLegalRepresentative.mail',
        'externalLegalRepresentative.phone',
      ]),
    )
  }

  if (orderData?.holders && orderData.holders.length > 0) {
    for (const holder of orderData.holders) {
      const { requiredFields } = getRequiredEntityFieldsBradesco('HOLDER', holder, orderData)
      dynamicFormValidator.push(...requiredFields)

      const dependents = holder.dependents ?? []
      for (const dependent of dependents) {
        const { requiredFields } = getRequiredEntityFieldsBradesco(
          'DEPENDENT',
          dependent,
          orderData,
        )
        dynamicFormValidator.push(...requiredFields)
      }
    }
  }

  return dynamicFormValidator.filter((value) => value)
}

export const getRequiredCompanyFieldsBradesco = (orderData?: GetOrderInformationBody) => {
  return [
    orderData?.company?.document,
    orderData?.company?.phone,
    orderData?.company?.mail,
    orderData?.company?.address?.zipCode,
    orderData?.company?.address?.number,
    orderData?.company?.externalLegalRepresentative?.name,
    orderData?.company?.externalLegalRepresentative?.role,
    orderData?.company?.externalLegalRepresentative?.mail,
    orderData?.company?.externalLegalRepresentative?.phone,
  ]
}

export const getRequiredHolderGroupFieldsBradesco = (orderData?: GetOrderInformationBody) => {
  const holderList = orderData?.holders?.map((_, index) => {
    return [
      orderData?.holders?.at(index)?.name,
      orderData?.holders?.at(index)?.maritalStatus,
      orderData?.holders?.at(index)?.gender,
      orderData?.holders?.at(index)?.rg,
      orderData?.holders?.at(index)?.cpf,
      orderData?.holders?.at(index)?.birthDate,
      orderData?.holders?.at(index)?.phone,
      orderData?.holders?.at(index)?.mail,
      orderData?.holders?.at(index)?.isAdministrativeLife,
      orderData?.holders?.at(index)?.address?.zipCode,
      orderData?.holders?.at(index)?.address?.entityAddress,
      orderData?.holders?.at(index)?.address?.number,
      ...(orderData?.quotation?.product === 'HEALTH'
        ? [
            orderData?.holders?.at(index)?.healthPlan?.catalogPlanId,
            orderData?.holders?.at(index)?.healthPlan?.accommodationType,
            orderData?.holders?.at(index)?.healthPlan?.lifeAmount,
            orderData?.holders?.at(index)?.healthPlan?.coparticipation,
          ]
        : []),
    ]
  })

  const allDependents = getRequiredDependentFieldsBradesco(orderData)

  return [...(holderList?.at(0) ?? []), ...allDependents]
}

export const getRequiredDependentFieldsBradesco = (orderData?: GetOrderInformationBody) => {
  const dependentList =
    orderData?.holders?.map((holder) =>
      holder?.dependents?.map((dependent: Entity) => [
        dependent.name,
        dependent.holderKinship,
        dependent.maritalStatus,
        dependent.gender,
        dependent.cpf,
        dependent.rg,
        dependent.birthDate,
        dependent.isAdministrativeLife,
        ...(dependent.hiredHealthPlan?.isActive
          ? [dependent.hiredHealthPlan.activeHiredPlanInsurer, dependent.hiredHealthPlan.hiredAt]
          : []),
      ]),
    ) ?? []

  return dependentList.flat(2)
}

export const getRequiredEntityFieldsBradesco = (
  entityType: EntityType,
  entity?: Entity | null,
  orderData?: GetOrderInformationBody,
) => {
  const requiredFields: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = []

  if (entityType === 'HOLDER') {
    requiredFields.push(
      getRequiredEntityFields(entity, [
        'name',
        'maritalStatus',
        'gender',
        'rg',
        'cpf',
        'birthDate',
        'phone',
        'mail',
        'contractType',
        'isAdministrativeLife',
        'address.zipCode',
        'address.entityAddress',
        'address.number',
        ...(orderData?.quotation?.product === 'HEALTH' || orderData?.quotation?.isCombinedProposal
          ? [
              'healthPlan.catalogPlanId',
              'healthPlan.accommodationType',
              'healthPlan.lifeAmount',
              'healthPlan.coparticipation',
            ]
          : []),
        ...(orderData?.quotation?.product === 'DENTAL' || orderData?.quotation?.isCombinedProposal
          ? ['dentalPlan.catalogPlanId', 'dentalPlan.accommodationType', 'dentalPlan.lifeAmount']
          : []),

        ...(entity?.hiredHealthPlan?.isActive
          ? [
              'hiredHealthPlan.registrationNumber',
              'hiredHealthPlan.activeHiredPlanInsurer',
              'hiredHealthPlan.hiredAt',
            ]
          : []),
      ]),
    )
  }

  if (entityType === 'DEPENDENT') {
    requiredFields.push(
      getRequiredEntityFields(entity, [
        'name',
        'holderKinship',
        'maritalStatus',
        'gender',
        'cpf',
        'rg',
        'birthDate',
        'isAdministrativeLife',
        ...(entity?.hiredHealthPlan?.isActive
          ? [
              'hiredHealthPlan.registrationNumber',
              'hiredHealthPlan.activeHiredPlanInsurer',
              'hiredHealthPlan.hiredAt',
            ]
          : []),
      ]),
    )
  }
  return {
    requiredFields,
    hasEmptyValues:
      requiredFields?.some(
        (validator) => validator?.requiredFields && validator.requiredFields.length > 0,
      ) ?? false,
  }
}
