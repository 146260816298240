import { useEffect } from 'react'

import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useVariant } from '@unleash/proxy-client-react'
import { triggerToast } from 'design-system/triggers'

import { getCatalogPlanOptions } from '@/App/clients/catalogApi/queries/catalogPlan'
import { Entity, GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { useFillOrderInformation } from '@/App/clients/healthPlansOrders/mutations/fillOrderInformation'
import { useGetCountriesQuery } from '@/App/clients/healthPlansOrders/queries/getCountries'
import { useInsurerFields } from '@/App/clients/insurerEdge/queries/getFields'
import { useDynamicForm } from '@/App/contexts/dynamicForm/DynamicFormProvider'
import { assembleInsurer } from '@/App/utils/assembleInsurer'
import { toCamelCase } from '@/App/utils/string'

import { AccordionFrame } from '../accordion'
import { getDependentSchema } from '../schemas/dependent'
import { getHolderSchema } from '../schemas/holder'
import { HolderGroupAccordionsDiv } from '../style'
import { DynamicForm } from './DynamicForm'

export interface HolderGroupAccordionProps {
  entityData?: Entity | null
  validateOrderDispatch: boolean
  validateSiswebDispatch: boolean
  orderInformationQuery?: UseQueryResult<GetOrderInformationBody, Error>
  isOcrFilled: boolean
  isOcrProcessing: boolean
  setIsOcrProcessing: React.Dispatch<React.SetStateAction<boolean>>
  genderPrediction: 'female' | 'male' | undefined
}

export const HolderGroupAccordionForm: React.FC<HolderGroupAccordionProps> = ({
  entityData,
  orderInformationQuery,
  validateOrderDispatch,
  validateSiswebDispatch,
  isOcrFilled,
  isOcrProcessing,
  setIsOcrProcessing,
  genderPrediction,
}) => {
  const odontoPfModalityId = useVariant('bliss-intranet-odonto-query-id')?.payload?.value
  const odontoPmeModalityId = useVariant('bliss-intranet-odonto-pme-query-id')?.payload?.value

  const getPlansQuery = useQuery(
    getCatalogPlanOptions({
      insurerId: orderInformationQuery?.data?.quotation?.insurerId,
      modalityId: orderInformationQuery?.data?.quotation?.typeId,
      enabled: !!orderInformationQuery?.data?.quotation,
    }),
  )
  const getOdontoPlansQuery = useQuery(
    getCatalogPlanOptions({
      insurerId: orderInformationQuery?.data?.quotation?.insurerId,
      modalityId:
        orderInformationQuery?.data?.type === 'PERSON' ? odontoPfModalityId : odontoPmeModalityId,
      enabled: !!orderInformationQuery?.data?.quotation,
    }),
  )
  const getCountriesQuery = useGetCountriesQuery()
  const fillOrderInformationMutation = useFillOrderInformation()
  const dynamicFormContext = useDynamicForm()
  const insurer = toCamelCase(
    assembleInsurer(
      orderInformationQuery?.data?.quotation?.insurer ?? orderInformationQuery?.data?.insurer,
    ),
  )
  const insurerEdge = useVariant('bliss-intranet-insurer-edge')?.payload?.value
  const insurerEdgeFlags = JSON.parse(insurerEdge ?? '{}')
  const isInsurerEdgeEnabled =
    insurerEdgeFlags[insurer ?? ''] || insurerEdgeFlags['default'] || false
  const insurerFieldsQuery = useInsurerFields({
    fieldType: 'DROPDOWN',
    insurerId: orderInformationQuery?.data?.quotation?.insurerId,
    enabled: isInsurerEdgeEnabled,
  })

  useEffect(() => {
    if (getPlansQuery?.isSuccess) {
      dynamicFormContext.setPlanOptions(getPlansQuery?.data)
    }
  }, [getPlansQuery?.isSuccess, getPlansQuery?.data])

  useEffect(() => {
    if (getOdontoPlansQuery?.isSuccess) {
      dynamicFormContext.setOdontoPlanOptions(getOdontoPlansQuery?.data)
    }
  }, [getOdontoPlansQuery?.isSuccess, getOdontoPlansQuery?.data])

  useEffect(() => {
    if (fillOrderInformationMutation.isSuccess) {
      triggerToast(true, 'Dados atualizados com sucesso!')
    } else if (fillOrderInformationMutation.isError) {
      triggerToast(false, 'Ops, ocorreu um erro ao atualizar os dados')
    }
  }, [fillOrderInformationMutation.isSuccess, fillOrderInformationMutation.isError])

  return (
    <HolderGroupAccordionsDiv>
      <AccordionFrame
        isInner={true}
        backgroundColor="#F0F1F4"
        key={entityData?.id}
        entityType="HOLDER"
        entityData={entityData}
        content={() => {
          const hasEntityCreatedBeforeOrderWasHired =
            String(entityData?.createdAt) < String(orderInformationQuery?.data?.hiredAt)

          const holderSchema = getHolderSchema({
            insurer: toCamelCase(
              assembleInsurer(
                orderInformationQuery?.data?.quotation?.insurer ??
                  orderInformationQuery?.data?.insurer,
              ),
            ),
            product: toCamelCase(orderInformationQuery?.data?.quotation?.product),
            planOptions: dynamicFormContext.planOptions,
            odontoPlanOptions: dynamicFormContext.odontoPlanOptions,
            countries: getCountriesQuery?.data,
            isOcrFilled,
            isRegisterInInsurer: orderInformationQuery?.data?.isRegisteredInInsurer,
            genderPrediction,
            isCombinedProposal: orderInformationQuery?.data?.quotation?.isCombinedProposal,
            enableHiredFields:
              orderInformationQuery?.data?.status === 'HIRED' &&
              !hasEntityCreatedBeforeOrderWasHired,
            insurerFields: insurerFieldsQuery?.data,
            isInsurerEdgeEnabled,
          })

          return (
            <DynamicForm
              entityData={entityData}
              schema={holderSchema}
              entityType="HOLDER"
              financialOfficerId={orderInformationQuery?.data?.financialOfficer?.id}
              holders={orderInformationQuery?.data?.holders}
            />
          )
        }}
        orderInformationQuery={orderInformationQuery}
        validateOrderDispatch={validateOrderDispatch}
        validateSiswebDispatch={validateSiswebDispatch}
        isOcrProcessing={isOcrProcessing}
        setIsOcrProcessing={setIsOcrProcessing}
      />

      {entityData?.dependents?.map((dependent, index) => {
        const hasEntityCreatedBeforeOrderWasHired =
          String(dependent.createdAt) < String(orderInformationQuery?.data?.hiredAt)

        const dependentSchema = getDependentSchema({
          insurer: toCamelCase(
            assembleInsurer(
              orderInformationQuery?.data?.quotation?.insurer ??
                orderInformationQuery?.data?.insurer,
            ),
          ),
          product: toCamelCase(orderInformationQuery?.data?.quotation?.product),
          planOptions: dynamicFormContext.planOptions,
          odontoPlanOptions: dynamicFormContext.odontoPlanOptions,
          countries: getCountriesQuery?.data,
          isOcrFilled,
          isRegisterInInsurer: orderInformationQuery?.data?.isRegisteredInInsurer,
          genderPrediction,
          isCombinedProposal: orderInformationQuery?.data?.quotation?.isCombinedProposal,
          enableHiredFields:
            orderInformationQuery?.data?.status === 'HIRED' && !hasEntityCreatedBeforeOrderWasHired,
          insurerFields: insurerFieldsQuery?.data,
          isInsurerEdgeEnabled,
        })

        return (
          <AccordionFrame
            isInner={true}
            backgroundColor="#F0F1F4"
            key={dependent.id}
            entityType="DEPENDENT"
            entityData={dependent}
            index={index}
            content={() => {
              return (
                <DynamicForm
                  entityData={dependent}
                  schema={dependentSchema}
                  entityType="DEPENDENT"
                  financialOfficerId={orderInformationQuery?.data?.financialOfficer?.id}
                  holders={orderInformationQuery?.data?.holders}
                />
              )
            }}
            orderInformationQuery={orderInformationQuery}
            validateOrderDispatch={validateOrderDispatch}
            validateSiswebDispatch={validateSiswebDispatch}
            isOcrProcessing={isOcrProcessing}
            setIsOcrProcessing={setIsOcrProcessing}
          />
        )
      })}
    </HolderGroupAccordionsDiv>
  )
}
