import { Entity, GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'

import type { EntityType } from '../..'
import { getRequiredEntityFields } from '../helper'

export const getRequiredFieldsAlice = (orderData?: GetOrderInformationBody) => {
  const dynamicFormValidator: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = [getRequiredEntityFields(orderData?.quotation, ['totalAmount'])]

  if (orderData?.quotation?.type === 'COMPANY') {
    dynamicFormValidator.push(
      getRequiredEntityFields(orderData?.company, [
        // company
        'document',
        'tradingName',
        'openingDate',
        'address.zipCode',
        'address.number',

        // externalLegalRepresentative
        'externalLegalRepresentative.name',
        'externalLegalRepresentative.phone',
        'externalLegalRepresentative.mail',

        // Contact
        'name',
        'phone',
        'mail',

        // financialOfficer
      ]),
    )
  }

  if (orderData?.holders && orderData.holders.length > 0) {
    for (const holder of orderData.holders) {
      const { requiredFields } = getRequiredEntityFieldsAlice('HOLDER', holder, orderData)
      dynamicFormValidator.push(...requiredFields)

      const dependents = holder.dependents ?? []
      for (const dependent of dependents) {
        const { requiredFields } = getRequiredEntityFieldsAlice('DEPENDENT', dependent, orderData)
        dynamicFormValidator.push(...requiredFields)
      }
    }
  }

  return dynamicFormValidator.filter((value) => value)
}

export const getRequiredCompanyFieldsAlice = (orderData?: GetOrderInformationBody) => {
  return [
    orderData?.company?.document,
    orderData?.company?.tradingName,
    orderData?.company?.type,
    orderData?.company?.registrationStatus,
    orderData?.company?.openingDate,
    orderData?.company?.address?.zipCode,
    orderData?.company?.address?.number,

    orderData?.company?.name,
    orderData?.company?.role,
    orderData?.company?.phone,
    orderData?.company?.mail,

    orderData?.company?.externalLegalRepresentative?.name,
    orderData?.company?.externalLegalRepresentative?.cpf,
    orderData?.company?.externalLegalRepresentative?.phone,
    orderData?.company?.externalLegalRepresentative?.mail,
  ]
}

export const getRequiredHolderGroupFieldsAlice = (orderData?: GetOrderInformationBody) => {
  const holderList = orderData?.holders?.map((_, index) => {
    return [
      orderData?.holders?.at(index)?.cpf,
      orderData?.holders?.at(index)?.birthDate,
      orderData?.holders?.at(index)?.name,
      orderData?.holders?.at(index)?.maritalStatus,
      orderData?.holders?.at(index)?.gender,
      orderData?.holders?.at(index)?.phone,
      orderData?.holders?.at(index)?.motherName,
      orderData?.holders?.at(index)?.contractType,
      orderData?.holders?.at(index)?.contractDate,
      orderData?.holders?.at(index)?.gracePeriodReduction,
      orderData?.holders?.at(index)?.isAdministrativeLife,
      orderData?.holders?.at(index)?.address?.zipCode,
      orderData?.holders?.at(index)?.address?.number,
      orderData?.holders?.at(index)?.address?.entityAddress,
      orderData?.holders?.at(index)?.healthPlan?.catalogPlanId,
    ]
  })

  const allDependents = getRequiredDependentFieldsAlice(orderData)

  return [...(holderList?.at(0) ?? []), ...allDependents]
}

export const getRequiredDependentFieldsAlice = (orderData?: GetOrderInformationBody) => {
  const dependentList =
    orderData?.holders?.map((holder) =>
      holder?.dependents?.map((dependent: Entity) => [
        dependent.birthDate,
        dependent.name,
        dependent.maritalStatus,
        dependent.gender,
        dependent.holderKinship,
        dependent.cpf,
        dependent.isAdministrativeLife,
        dependent.motherName,
        dependent.phone,
        dependent.gracePeriodReduction,
      ]),
    ) ?? []

  const allDependents = dependentList.flat(2)

  return allDependents
}

export const getRequiredEntityFieldsAlice = (
  entityType: EntityType,
  entity?: Entity | null,
  orderData?: GetOrderInformationBody,
) => {
  const requiredFields: Array<{
    dynamicFormId?: string | null | undefined
    requiredFields: Array<string | null>
  } | null> = []

  if (entityType === 'HOLDER') {
    requiredFields.push(
      getRequiredEntityFields(entity, [
        'cpf',
        'birthDate',
        'name',
        'mail',
        'maritalStatus',
        'gender',
        'phone',
        'isAdministrativeLife',
        'motherName',
        'contractType',
        'contractDate',
        'gracePeriodReduction',
        'address.zipCode',
        'address.number',
        'address.entityAddress',
        ...(orderData?.quotation?.product === 'HEALTH' || orderData?.quotation?.isCombinedProposal
          ? ['healthPlan.catalogPlanId']
          : []),
        ...(orderData?.quotation?.product === 'DENTAL' || orderData?.quotation?.isCombinedProposal
          ? ['dentalPlan.catalogPlanId']
          : []),
      ]),
    )
  }

  if (entityType === 'DEPENDENT') {
    requiredFields.push(
      getRequiredEntityFields(entity, [
        'birthDate',
        'name',
        'maritalStatus',
        'gender',
        'holderKinship',
        'cpf',
        'isAdministrativeLife',
        'phone',
        'motherName',
        'gracePeriodReduction',
      ]),
    )
  }

  return {
    requiredFields,
    hasEmptyValues:
      requiredFields?.some(
        (validator) => validator?.requiredFields && validator.requiredFields.length > 0,
      ) ?? false,
  }
}
