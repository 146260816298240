export const amilKinships = [
  { label: 'Pai/Mãe', value: 'FATHER' },
  { label: 'Cônjuge', value: 'SPOUSE' },
  { label: 'Filho/Filha', value: 'CHILDREN' },
  { label: 'Neto (a)', value: 'GRANDSON' },
  { label: 'Irmãos', value: 'SIBLINGS' },
  { label: 'Sobrinhos(as)', value: 'NEPHEWS' },
  { label: 'Genro e Nora', value: 'SON-IN-LAW' },
  { label: 'Padrasto e Madrasta', value: 'STEPFATHER' },
  { label: 'Enteado(a)', value: 'STEPCHILDREN' },
]
