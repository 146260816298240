import { Decimal } from 'decimal.js'
import GroupIcon from 'design-system/group'
import Person from 'design-system/person'
import StoreIcon from 'design-system/store'
import { v4 as uuidv4 } from 'uuid'

import {
  Entity,
  GetOrderInformationBody,
  Plan,
  type Quotation,
} from '@/App/clients/healthPlansOrders/dtos'
import { calculateAge } from '@/App/utils/calculateProcessingTime'
import { ONE_KILOGRAM_IN_GRAM, ONE_METER_IN_CENTIMETER } from '@/App/utils/constants'
import { transformEntityAddressToAddress } from '@/App/utils/parseAddress'
import { clearInputMask } from '@/App/utils/string'

import { EntityType } from '..'

export enum EntityTypeLocale {
  'HOLDER' = 'Titular',
  'DEPENDENT' = 'Dependente',
  'COMPANY' = 'Empresa',
}

export const getTitle = (type: EntityType, index: number): string => {
  switch (type) {
    case 'HOLDER':
      return 'Titular'

    case 'HOLDER_GROUP':
      return `Grupo Titular ${index + 1}`

    case 'DEPENDENT':
      return `Dependente ${index + 1}`

    case 'COMPANY':
      return 'Empresa'
  }
}

export const getIcon = (type: EntityType): JSX.Element => {
  switch (type) {
    case 'HOLDER':
      return <Person />

    case 'HOLDER_GROUP':
      return <GroupIcon />

    case 'DEPENDENT':
      return <Person />

    case 'COMPANY':
      return <StoreIcon />
  }
}

export const getRequiredExternalBrokerFields = (orderData: GetOrderInformationBody) => {
  return orderData?.externalBroker != null ? Object.values(orderData.externalBroker) : []
}

export const getRequiredExternalManagerFields = (orderData: GetOrderInformationBody) => {
  return orderData?.externalManager != null ? Object.values(orderData.externalManager) : []
}

export const generateHiredPlansArray = (
  data: Entity,
  entityData?: Entity | null,
  type?: string,
) => {
  const hiredPlansArray: Array<Plan> = []

  let healthPlanData: Plan = {}
  let dentalPlanData: Plan = {}

  entityData?.hiredPlans?.map((plan) => {
    if (plan.type === 'HEALTH') {
      healthPlanData = { ...healthPlanData, ...plan }
    } else {
      dentalPlanData = { ...dentalPlanData, ...plan }
    }
  })

  const healthPlanObject =
    data?.hiredHealthPlan?.isActive?.toString() === 'true' || type === 'healthDeclarationPermanence'
      ? {
          id: healthPlanData?.id ?? uuidv4(),
          activeHiredPlanInsurer:
            data?.hiredHealthPlan?.activeHiredPlanInsurer !== undefined
              ? data.hiredHealthPlan.activeHiredPlanInsurer
              : healthPlanData.activeHiredPlanInsurer,
          hiredAt:
            data?.hiredHealthPlan?.hiredAt !== undefined
              ? data.hiredHealthPlan?.hiredAt
              : healthPlanData?.hiredAt,
          lastPaymentAt:
            data?.hiredHealthPlan?.lastPaymentAt !== undefined
              ? data.hiredHealthPlan.lastPaymentAt
              : healthPlanData.lastPaymentAt,
          registrationNumber:
            data?.hiredHealthPlan?.registrationNumber !== undefined
              ? data.hiredHealthPlan.registrationNumber
              : healthPlanData.registrationNumber,
          personInternalId: data?.id,
          type: 'HEALTH',
          isActive: data?.hiredHealthPlan?.isActive?.toString() === 'true',
          contractType:
            data?.hiredHealthPlan?.contractType !== undefined
              ? data.hiredHealthPlan?.contractType
              : healthPlanData?.contractType,
          modality:
            data?.hiredHealthPlan?.modality !== undefined
              ? data.hiredHealthPlan?.modality
              : healthPlanData?.modality,
          accommodationType:
            data?.hiredHealthPlan?.accommodationType !== undefined
              ? data.hiredHealthPlan?.accommodationType
              : healthPlanData?.accommodationType,
        }
      : null

  const dentalPlanObject =
    data?.hiredDentalPlan?.isActive?.toString() === 'true' || type === 'dentalDeclarationPermanence'
      ? {
          id: dentalPlanData?.id ?? uuidv4(),
          activeHiredPlanInsurer:
            data?.hiredDentalPlan?.activeHiredPlanInsurer !== undefined
              ? data.hiredDentalPlan.activeHiredPlanInsurer
              : dentalPlanData.activeHiredPlanInsurer,
          hiredAt:
            data?.hiredDentalPlan?.hiredAt !== undefined
              ? data.hiredDentalPlan.hiredAt
              : dentalPlanData.hiredAt,
          lastPaymentAt:
            data?.hiredDentalPlan?.lastPaymentAt !== undefined
              ? data.hiredDentalPlan.lastPaymentAt
              : dentalPlanData.lastPaymentAt,
          registrationNumber:
            data?.hiredDentalPlan?.registrationNumber !== undefined
              ? data.hiredDentalPlan.registrationNumber
              : dentalPlanData.registrationNumber,
          personInternalId: data?.id,
          type: 'DENTAL',
          isActive: data?.hiredDentalPlan?.isActive?.toString() === 'true',
          contractType:
            data?.hiredDentalPlan?.contractType !== undefined
              ? data.hiredDentalPlan?.contractType
              : dentalPlanData?.contractType,
          modality:
            data?.hiredDentalPlan?.modality !== undefined
              ? data.hiredDentalPlan?.modality
              : dentalPlanData?.modality,
        }
      : null

  if (healthPlanObject != null) hiredPlansArray.push(healthPlanObject)
  if (dentalPlanObject != null) hiredPlansArray.push(dentalPlanObject)

  return hiredPlansArray
}

const getPlanName = (
  catalogPlanId?: string | null,
  planOptions?: Array<{
    label: string
    value: string
  }>,
) => {
  return planOptions?.find((plan) => plan.value === catalogPlanId)?.label
}

export const generateQuotationPlansArray = (
  data: Entity,
  entityData?: Entity | null,
  planOptions: Array<{ label: string; value: string }> = [],
  odontoPlanOptions: Array<{ label: string; value: string }> = [],
) => {
  const quotationPlansArray: Array<Plan> = []

  let healthPlanData: Plan = {}
  let dentalPlanData: Plan = {}

  entityData?.quotationPlans?.map((plan) => {
    if (plan.type === 'HEALTH') {
      healthPlanData = { ...healthPlanData, ...plan }
    } else {
      dentalPlanData = { ...dentalPlanData, ...plan }
    }
  })

  const hasHealthCatalogPlanId = data?.healthPlan?.catalogPlanId || healthPlanData?.catalogPlanId
  const hasHealthPlanName = data?.healthPlan?.planName || healthPlanData?.planName
  const hasHealthlAttributes =
    data?.healthPlan?.accommodationType ||
    healthPlanData?.accommodationType ||
    data?.healthPlan?.lifeAmount ||
    healthPlanData?.lifeAmount ||
    data?.healthPlan?.coparticipation ||
    healthPlanData?.coparticipation

  const catalogPlanId =
    data?.healthPlan?.catalogPlanId != undefined
      ? data.healthPlan.catalogPlanId
      : healthPlanData.catalogPlanId

  const catalogPlanIdDental =
    data?.dentalPlan?.catalogPlanId != undefined
      ? data.dentalPlan.catalogPlanId
      : dentalPlanData.catalogPlanId

  const healthPlanObject =
    hasHealthCatalogPlanId || hasHealthPlanName || hasHealthlAttributes
      ? {
          id: healthPlanData?.id ?? uuidv4(),
          personInternalId: data?.id,
          type: 'HEALTH',
          product: 'HEALTH',
          catalogPlanId,
          name: getPlanName(catalogPlanId, planOptions),
          accommodationType:
            data?.healthPlan?.accommodationType != undefined
              ? data.healthPlan.accommodationType
              : healthPlanData.accommodationType,
          lifeAmount:
            data?.healthPlan?.lifeAmount != 0 && data?.healthPlan?.lifeAmount != null
              ? Number(clearInputMask(data.healthPlan?.lifeAmount?.toString(), 'money'))
              : healthPlanData.lifeAmount,
          coparticipation:
            data?.healthPlan?.coparticipation != null
              ? data?.healthPlan.coparticipation
              : healthPlanData.coparticipation,
        }
      : null

  const hasDentalCatalogPlanId = data?.dentalPlan?.catalogPlanId || dentalPlanData?.catalogPlanId
  const hasDentalPlanName = data?.dentalPlan?.planName || dentalPlanData?.planName
  const hasDentalAttributes =
    data?.dentalPlan?.accommodationType ||
    dentalPlanData?.accommodationType ||
    data?.dentalPlan?.lifeAmount ||
    dentalPlanData?.lifeAmount ||
    data?.dentalPlan?.coparticipation ||
    dentalPlanData?.coparticipation

  const dentalPlanObject =
    hasDentalCatalogPlanId || hasDentalPlanName || hasDentalAttributes
      ? {
          id: dentalPlanData.id ?? uuidv4(),
          personInternalId: data?.id,
          type: 'DENTAL',
          product: 'DENTAL',
          catalogPlanId:
            data?.dentalPlan?.catalogPlanId != undefined
              ? data.dentalPlan.catalogPlanId
              : dentalPlanData.catalogPlanId,
          name: getPlanName(catalogPlanIdDental, odontoPlanOptions ?? planOptions),
          accommodationType:
            data?.dentalPlan?.accommodationType != undefined
              ? data.dentalPlan.accommodationType
              : dentalPlanData.accommodationType,
          lifeAmount:
            data?.dentalPlan?.lifeAmount != 0 && data?.dentalPlan?.lifeAmount != null
              ? Number(clearInputMask(data.dentalPlan?.lifeAmount?.toString(), 'money'))
              : dentalPlanData.lifeAmount,
          coparticipation:
            data?.dentalPlan?.coparticipation != null
              ? data?.dentalPlan.coparticipation
              : dentalPlanData.coparticipation,
        }
      : null

  if (healthPlanObject != null) quotationPlansArray.push(healthPlanObject)
  if (dentalPlanObject != null) quotationPlansArray.push(dentalPlanObject)

  return quotationPlansArray
}

type ParseFillEntityPayloadRequest = {
  entityData?: Entity | null
  formData: Entity
  entityType: string
  isDynamicForm?: boolean
  financialOfficerId?: string | null
  planOptions?: Array<{ label: string; value: string }>
  odontoPlanOptions?: Array<{ label: string; value: string }>
}

export function parseFillEntityPayloadRequest({
  entityData,
  entityType,
  formData,
  isDynamicForm = false,
  financialOfficerId,
  planOptions = [],
  odontoPlanOptions = [],
}: ParseFillEntityPayloadRequest) {
  let formDataAddress = formData?.entityAddress

  if (isDynamicForm) {
    formDataAddress = formData?.address?.entityAddress
  }

  const completeAddress =
    formDataAddress != null
      ? {
          ...transformEntityAddressToAddress(formDataAddress),
          complement: formData.address?.complement || null,
          number: formData.address?.number || null,
          zipCode: formData.address?.zipCode || null,
          neighborhood:
            transformEntityAddressToAddress(formDataAddress).neighborhood ??
            formData.address?.neighborhood ??
            null,
          city:
            transformEntityAddressToAddress(formDataAddress).city ?? formData.address?.city ?? null,
          state:
            transformEntityAddressToAddress(formDataAddress).state ??
            formData.address?.state ??
            null,
        }
      : null

  const parsedData = {
    ...formData,
    phone: formData.phone != null ? clearInputMask(formData.phone) : null,
    cpf: formData.cpf != null ? clearInputMask(formData.cpf) : null,
    address: completeAddress ?? formData.address,
    entityAddress: null,
    document: formData.document != null ? clearInputMask(formData.document) : null,
    weight:
      formData.weight != null
        ? new Decimal(formData.weight).mul(ONE_KILOGRAM_IN_GRAM).toNumber()
        : null,
    height:
      formData.height != null
        ? new Decimal(formData.height).mul(ONE_METER_IN_CENTIMETER).toNumber()
        : null,
  }

  const propertiesToDelete = ['hiredHealthPlan', 'hiredDentalPlan', 'dentalPlan', 'healthPlan']
  propertiesToDelete.forEach((property) => delete parsedData[property])

  return entityType === 'COMPANY'
    ? {
        company: {
          ...parsedData,
          id: entityData?.id,
          address: completeAddress ?? formData.address,
          externalLegalRepresentative: {
            ...parsedData.externalLegalRepresentative,
            id: entityData?.externalLegalRepresentative?.id,
            phone: parsedData.externalLegalRepresentative?.phone
              ? clearInputMask(parsedData.externalLegalRepresentative.phone)
              : null,
          },
        },
      }
    : {
        entities: [
          {
            ...parsedData,
            id: entityData?.id,
            hasActiveHiredPlan: formData.hasActiveHiredPlan === 'true',
            hiredPlans: generateHiredPlansArray(formData, entityData),
            quotationPlans: generateQuotationPlansArray(
              formData,
              entityData,
              planOptions,
              odontoPlanOptions,
            ),
            financialOfficer: {
              ...parsedData.financialOfficer,
              id: financialOfficerId,
              phone: clearInputMask(parsedData?.financialOfficer?.phone),
            },
          },
        ],
      }
}

export const getRequiredEntityFields = (
  entity?: Entity | Quotation | GetOrderInformationBody | null,
  fieldsToValidate?: Array<string> | null,
) => {
  if (!entity || !fieldsToValidate || fieldsToValidate.length === 0) {
    return null
  }

  const getNestedValue = (obj: Entity | GetOrderInformationBody | Quotation, path: string) =>
    path.split('.').reduce<unknown>((acc, part) => acc && acc[part], obj)

  return {
    dynamicFormId: entity.id,
    requiredFields: fieldsToValidate
      .map((field) => {
        const value = getNestedValue(entity, field)
        return value != null && value !== '' ? null : field
      })
      .filter((value) => value != null),
  }
}

export const getUpdatedLifeAmount = (
  holders: Entity[] | null,
  agesRange:
    | [
        {
          minAge: number
          maxAge: number
          amount: number
        },
      ]
    | undefined,
): Entity[] => {
  const entities: Entity[] = []

  if (agesRange !== undefined && holders) {
    for (const holder of holders) {
      const age = calculateAge(holder.birthDate)

      const healthHolderPlanInfos = holder?.quotationPlans?.find((plan) => {
        if (plan.type === 'HEALTH') return plan
      })
      const dentalHolderPlanInfos = holder?.quotationPlans?.find((plan) => {
        if (plan.type === 'DENTAL') return plan
      })

      for (const ageRange of agesRange) {
        if (age >= ageRange.minAge && age <= ageRange.maxAge) {
          entities.push({
            ...holder,
            quotationPlans: [
              {
                id: healthHolderPlanInfos?.id,
                personInternalId: holder.id,
                type: 'HEALTH',
                product: 'HEALTH',
                catalogPlanId: healthHolderPlanInfos?.catalogPlanId,
                name: healthHolderPlanInfos?.planName,
                accommodationType: healthHolderPlanInfos?.accommodationType,
                lifeAmount: ageRange.amount,
                coparticipation: healthHolderPlanInfos?.coparticipation,
              },
              dentalHolderPlanInfos
                ? {
                    id: dentalHolderPlanInfos?.id,
                    personInternalId: holder.id,
                    type: 'DENTAL',
                    product: 'DENTAL',
                    catalogPlanId: dentalHolderPlanInfos?.catalogPlanId,
                    name: dentalHolderPlanInfos?.planName,
                    accommodationType: dentalHolderPlanInfos?.accommodationType,
                    //todo retornar lifeamount para propostas conjugadas
                    //lifeAmount: ageRange.amount,
                    coparticipation: dentalHolderPlanInfos?.coparticipation,
                  }
                : {},
            ],
          })
        }
      }
      holder.dependents?.forEach((dependent) => {
        const age = calculateAge(dependent.birthDate)

        const healthPlanInfos = dependent?.quotationPlans?.find((plan) => {
          if (plan.type === 'HEALTH') return plan
        })
        const dentalPlanInfos = dependent.quotationPlans?.find((plan) => {
          if (plan.type === 'DENTAL') return plan
        })

        for (const ageRange of agesRange) {
          if (age >= ageRange.minAge && age <= ageRange.maxAge) {
            entities.push({
              ...dependent,
              quotationPlans: [
                {
                  id: healthPlanInfos?.id,
                  personInternalId: dependent.id,
                  type: 'HEALTH',
                  product: 'HEALTH',
                  catalogPlanId: healthHolderPlanInfos?.catalogPlanId,
                  name: healthHolderPlanInfos?.planName,
                  accommodationType: healthHolderPlanInfos?.accommodationType,
                  lifeAmount: ageRange.amount,
                  coparticipation: healthHolderPlanInfos?.coparticipation,
                },
                dentalHolderPlanInfos
                  ? {
                      id: dentalPlanInfos?.id,
                      personInternalId: dependent.id,
                      type: 'DENTAL',
                      product: 'DENTAL',
                      catalogPlanId: dentalHolderPlanInfos?.catalogPlanId,
                      name: dentalHolderPlanInfos?.planName,
                      accommodationType: dentalHolderPlanInfos?.accommodationType,
                      //todo retornar lifeamount para propostas conjugadas
                      //lifeAmount: ageRange.amount,
                      coparticipation: dentalHolderPlanInfos?.coparticipation,
                    }
                  : {},
              ],
            })
          }
        }
      })
    }
  }
  return entities
}

export function transformCommonOptionToInsurerEdgeOptions({
  options,
  title,
  type,
  placeholder,
  product = 'HEALTH',
}: {
  options: Array<{ label: string; value: string }>
  type: 'Holder' | 'Dependent' | 'Company'
  title: string
  placeholder: string
  product?: 'DENTAL' | 'HEALTH' | 'HEALTH_AND_DENTAL'
}) {
  return {
    title,
    placeholder,
    options: options.map((o) => ({
      type,
      intranetValue: o.label,
      blissValue: o.value,
      product,
    })),
  }
}
