import type { InsurerEdgeFields } from '@/App/clients/insurerEdge/dto'

import { getCompanySchema } from './company'
import { getDependentSchema } from './dependent'
import { getHolderSchema } from './holder'

export type GetSchemaProps = {
  insurer?: string | null
  product?: string | null
  planOptions?: Array<{ label: string; value: string }>
  odontoPlanOptions?: Array<{ label: string; value: string }>
  countries?: Array<{ label: string; value: string }>
  isOcrFilled?: boolean
  isRegisterInInsurer?: boolean
  genderPrediction?: 'female' | 'male'
  orderType?: string | null
  isCombinedProposal?: boolean
  enableHiredFields?: boolean
  isInsurerEdgeEnabled?: boolean
  insurerFields?: Array<InsurerEdgeFields>
}

export const getDynamicSchema = ({
  orderType,
  insurer,
  planOptions,
  odontoPlanOptions,
  product,
  countries,
  isOcrFilled = false,
  isRegisterInInsurer = false,
  genderPrediction,
  isCombinedProposal,
  enableHiredFields = false,
  isInsurerEdgeEnabled,
  insurerFields,
}: GetSchemaProps) => {
  return {
    company: getCompanySchema({
      isOcrFilled,
      insurer,
      isRegisterInInsurer,
      isInsurerEdgeEnabled,
      insurerFields,
    }),
    dependent: getDependentSchema({
      orderType,
      insurer,
      product,
      countries,
      isOcrFilled,
      isRegisterInInsurer,
      genderPrediction,
      isCombinedProposal,
      enableHiredFields,
      insurerFields,
      isInsurerEdgeEnabled,
    }),
    holder: getHolderSchema({
      orderType,
      insurer,
      product,
      planOptions,
      odontoPlanOptions,
      countries,
      isOcrFilled,
      isRegisterInInsurer,
      genderPrediction,
      isCombinedProposal,
      enableHiredFields,
      insurerFields,
      isInsurerEdgeEnabled,
    }),
  }
}
