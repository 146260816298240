import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'

import type { GetSchemaProps } from '../..'
import { amilAccommodationType } from '../../../helper/amil/accommodationType'
import { amilContractTypes } from '../../../helper/amil/contractType'
import { amilGender } from '../../../helper/amil/gender'
import { insurersAmil } from '../../../helper/amil/insurers'
import { amilIssuingAgencies } from '../../../helper/amil/issuingAgencies'
import { amilMaritalStatus } from '../../../helper/amil/maritalStatus'
import { transformCommonOptionToInsurerEdgeOptions } from '../../../helper/helper'

export function holderAmilDentalSchema({
  planOptions,
  isOcrFilled,
  genderPrediction,
  enableHiredFields,
  insurerFields,
  isInsurerEdgeEnabled,
}: GetSchemaProps): DynamicFormSchemaProps {
  const maritalStatus = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'maritalStatus')
    : transformCommonOptionToInsurerEdgeOptions({
        options: amilMaritalStatus,
        title: 'Estado Civil*',
        type: 'Holder',
        placeholder: 'Selecione o estado civil',
      })
  const gender = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'gender')
    : transformCommonOptionToInsurerEdgeOptions({
        options: amilGender,
        title: 'Gênero*',
        type: 'Holder',
        placeholder: 'Selecione o gênero',
      })
  const contractType = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'contractType')
    : transformCommonOptionToInsurerEdgeOptions({
        options: amilContractTypes,
        title: 'Tipo de Contratação*',
        type: 'Holder',
        placeholder: 'Selecione o tipo de contratação',
      })
  const issuingAgency = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'issuingAgency')
    : transformCommonOptionToInsurerEdgeOptions({
        options: amilIssuingAgencies,
        title: 'Órgão Emissor*',
        type: 'Holder',
        placeholder: 'Selecione o órgão emissor',
      })
  const accommodationType = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'accommodationType')
    : transformCommonOptionToInsurerEdgeOptions({
        options: amilAccommodationType,
        title: 'Acomodação*',
        type: 'Holder',
        placeholder: 'Selecione a acomodação',
      })
  const activeHiredPlanInsurer = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'activeHiredPlanInsurer')
    : transformCommonOptionToInsurerEdgeOptions({
        options: insurersAmil,
        title: 'Operadora Atual*',
        type: 'Holder',
        placeholder: 'Selecione a operadora atual',
      })

  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        name: {
          type: ['string', 'null'],
          title: 'Nome Completo*',
        },
        isPartner: {
          type: 'boolean',
          title: 'Sócio/Cônjuge de sócio da empresa?',
        },
        maritalStatus: {
          type: ['string', 'null'],
          title: maritalStatus?.title,
          oneOf:
            maritalStatus?.options
              .filter((o) => o.type === 'Holder')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        gender: {
          type: ['string', 'null'],
          title: gender?.title,
          oneOf:
            gender?.options
              .filter((o) => o.type === 'Holder')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
          default: genderPrediction?.toUpperCase() ?? null,
        },
        contractType: {
          type: ['string', 'null'],
          title: contractType?.title,
          oneOf:
            contractType?.options
              .filter((o) => o.type === 'Holder')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        contractDate: {
          type: ['string', 'null'],
          title: 'Data de Contratação*',
          format: 'date',
        },
        cpf: {
          type: ['string', 'null'],
          title: 'CPF*',
        },
        rg: {
          type: ['string', 'null'],
          title: 'RG*',
        },
        issuingAgency: {
          type: ['string', 'null'],
          title: issuingAgency?.title,
          oneOf:
            issuingAgency?.options
              .filter((o) => o.type === 'Holder')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        birthDate: {
          type: ['string', 'null'],
          title: 'Data de Nascimento*',
          format: 'date',
        },
        phone: {
          type: ['string', 'null'],
          title: 'Telefone*',
        },
        mail: {
          type: ['string', 'null'],
          title: 'E-mail*',
          format: 'email',
        },
        motherName: {
          type: ['string', 'null'],
          title: 'Nome da Mãe*',
        },
        isAdministrativeLife: {
          type: ['boolean', 'null'],
          title: 'É uma vida administrativa?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: null,
        },
        height: {
          type: ['string', 'null'],
          title: 'Altura (m)',
        },
        weight: {
          type: ['string', 'null'],
          title: 'Peso (kg)',
        },
        address: {
          type: 'object',
          properties: {
            zipCode: {
              type: ['string', 'null'],
              title: 'CEP*',
            },
            entityAddress: {
              type: ['string', 'null'],
              title: 'Endereço*',
            },
            number: {
              type: ['string', 'null'],
              title: 'Número*',
            },
            complement: {
              type: ['string', 'null'],
              title: 'Complemento',
            },
            neighborhood: {
              type: 'string',
              title: 'Bairro',
            },
            city: {
              type: ['string', 'null'],
              title: 'Cidade',
            },
            state: {
              type: ['string', 'null'],
              title: 'Estado',
            },
          },
        },
        dentalPlan: {
          type: 'object',
          properties: {
            catalogPlanId: {
              type: ['string', 'null'],
              title: 'Plano Dental*',
              oneOf:
                planOptions && planOptions.length > 0
                  ? planOptions.map((plan) => ({
                      title: plan.label,
                      const: plan.value,
                    }))
                  : [{ title: '', const: '' }],
            },
            accommodationType: {
              type: ['string', 'null'],
              title: accommodationType?.title,
              oneOf:
                accommodationType?.options
                  .filter((o) => o.type === 'Holder')
                  .map((option) => ({
                    title: option.intranetValue,
                    const: option.blissValue,
                  })) ?? [],
            },
            lifeAmount: {
              type: ['string', 'null'],
              title: 'Valor Vida*',
            },
          },
        },
        hiredHealthPlan: {
          type: 'object',
          properties: {
            isActive: {
              type: 'boolean',
              title: 'Possui plano médico?',
              oneOf: [
                { title: 'Sim', const: true },
                { title: 'Não', const: false },
              ],
              default: false,
            },
          },
          dependencies: {
            isActive: {
              oneOf: [
                {
                  properties: {
                    isActive: {
                      enum: [false],
                    },
                  },
                },
                {
                  properties: {
                    isActive: {
                      enum: [true],
                    },
                    hiredHealthSubtitle: {
                      type: ['string', 'null'],
                      title: 'Dados do Plano Médico Atual',
                    },
                    registrationNumber: {
                      type: ['string', 'null'],
                      title: 'Matrícula',
                    },
                    activeHiredPlanInsurer: {
                      type: ['string', 'null'],
                      title: activeHiredPlanInsurer?.title,
                      oneOf:
                        activeHiredPlanInsurer?.options
                          .filter((o) => o.type === 'Holder')
                          .map((option) => ({
                            title: option.intranetValue,
                            const: option.blissValue,
                          })) ?? [],
                    },
                    hiredAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data de Início',
                    },
                    lastPaymentAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data do Último Pagamento',
                    },
                  },
                },
              ],
            },
          },
        },
        hiredDentalPlan: {
          type: 'object',
          properties: {
            isActive: {
              type: 'boolean',
              title: 'Possui plano dental?',
              oneOf: [
                { title: 'Sim', const: true },
                { title: 'Não', const: false },
              ],
              default: false,
            },
          },
          dependencies: {
            isActive: {
              oneOf: [
                {
                  properties: {
                    isActive: {
                      enum: [false],
                    },
                  },
                },
                {
                  properties: {
                    isActive: {
                      enum: [true],
                    },
                    hiredDentalSubtitle: {
                      type: ['string', 'null'],
                      title: 'Dados do Plano Dental Atual',
                    },
                    activeHiredPlanInsurer: {
                      type: ['string', 'null'],
                      title: activeHiredPlanInsurer?.title,
                      oneOf:
                        activeHiredPlanInsurer?.options
                          .filter((o) => o.type === 'Holder')
                          .map((option) => ({
                            title: option.intranetValue,
                            const: option.blissValue,
                          })) ?? [],
                    },
                    hiredAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data de Início',
                    },
                    lastPaymentAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data do Último Pagamento',
                    },
                  },
                },
              ],
            },
          },
        },
        isLegalAge: {
          type: 'boolean',
        },
      },
      dependencies: {
        isLegalAge: {
          oneOf: [
            {
              properties: {
                isLegalAge: {
                  enum: [true],
                },
              },
            },
            {
              properties: {
                isLegalAge: {
                  enum: [false],
                },
                financialOfficer: {
                  type: 'object',
                  properties: {
                    financialOfficerSubtitle: {
                      type: 'string',
                      title: 'Responsável Financeiro',
                    },
                    name: {
                      type: 'string',
                      title: 'Nome Completo',
                    },
                    mail: {
                      type: 'string',
                      title: 'E-mail',
                      format: 'email',
                    },
                    phone: {
                      type: 'string',
                      title: 'Telefone',
                    },
                    cpf: {
                      type: 'string',
                      title: 'CPF*',
                    },
                    birthDate: {
                      type: 'string',
                      title: 'Data Nascimento',
                      format: 'date',
                    },
                  },
                },
              },
            },
          ],
        },
      },
    },
    uiSchema: {
      name: {
        'ui:placeholder': 'Ex.: Maria da Silva',
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      maritalStatus: {
        'ui:disabled': !maritalStatus,
        'ui:placeholder': maritalStatus?.placeholder,
      },
      gender: {
        'ui:disabled': !gender,
        'ui:placeholder': gender?.placeholder,
      },
      contractType: {
        'ui:disabled': !contractType,
        'ui:placeholder': contractType?.placeholder,
      },
      contractDate: {
        'ui:placeholder': '00/00/0000',
      },
      cpf: {
        'ui:placeholder': '000.000.000-00',
        'ui:options': {
          mask: 'cpf',
        },
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      rg: {
        'ui:placeholder': '000000000',
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      issuingAgency: {
        'ui:disabled': isOcrFilled || !maritalStatus,
        'ui:placeholder': maritalStatus?.placeholder,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      birthDate: {
        'ui:placeholder': '00/00/0000',
      },
      phone: {
        'ui:placeholder': '(00) 00000 0000',
        'ui:options': {
          mask: 'phone',
        },
      },
      mail: {
        'ui:placeholder': 'exemplo@email.com',
        'ui:widget': 'TextWidget',
      },
      motherName: {
        'ui:placeholder': 'Ex.: Ana da Silva',
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      isAdministrativeLife: {
        'ui:widget': 'radio',
      },
      height: {
        'ui:placeholder': 'Ex.: 1.76m',
        'ui:options': {
          mask: 'height',
        },
        'ui:widget': enableHiredFields ? 'TextWidget' : 'hidden',
      },
      weight: {
        'ui:placeholder': 'Ex.: 86.60kg',
        'ui:options': {
          mask: 'weight',
        },
        'ui:widget': enableHiredFields ? 'TextWidget' : 'hidden',
      },
      address: {
        'ui:options': {
          colSpan: 2,
        },
        zipCode: {
          'ui:placeholder': '00000-000',
          'ui:disabled': isOcrFilled,
          'ui:options': {
            mask: 'cep',
          },
        },
        entityAddress: {
          'ui:placeholder': 'Buscar endereço',
          'ui:widget': 'AutoCompleteWidget',
          'ui:disabled': isOcrFilled,
        },
        number: {
          'ui:placeholder': '000',
          'ui:disabled': isOcrFilled,
        },
        complement: {
          'ui:placeholder': 'Ex.: Apto 123',
        },
        neighborhood: {
          'ui:placeholder': 'Ex.: Pinheiros',
          'ui:disabled': isOcrFilled,
        },
        city: {
          'ui:placeholder': 'Ex.: São Paulo',
          'ui:disabled': isOcrFilled,
        },
        state: {
          'ui:placeholder': 'Ex.: SP',
          'ui:disabled': isOcrFilled,
        },
      },
      hiredHealthPlan: {
        'ui:options': {
          colSpan: 2,
        },
        isActive: {
          'ui:widget': 'radio',
          'ui:options': {
            colSpan: 2,
          },
        },
        hiredHealthSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        hiredDentalSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        registrationNumber: {
          'ui:placeholder': 'Nº de matrícula',
        },
        activeHiredPlanInsurer: {
          'ui:disabled': !activeHiredPlanInsurer,
          'ui:placeholder': activeHiredPlanInsurer?.placeholder,
        },
        hiredAt: {
          'ui:placeholder': '00/00/0000',
        },
        lastPaymentAt: {
          'ui:placeholder': '00/00/0000',
        },
      },
      hiredDentalPlan: {
        'ui:options': {
          colSpan: 2,
        },
        isActive: {
          'ui:widget': 'radio',
          'ui:options': {
            colSpan: 2,
          },
        },
        hiredDentalSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        activeHiredPlanInsurer: {
          'ui:disabled': !activeHiredPlanInsurer,
          'ui:placeholder': activeHiredPlanInsurer?.placeholder,
        },
        hiredAt: {
          'ui:placeholder': '00/00/0000',
        },
        lastPaymentAt: {
          'ui:placeholder': '00/00/0000',
        },
      },
      emptyColumn1: {
        'ui:widget': 'hidden',
      },
      financialOfficer: {
        'ui:options': {
          colSpan: 2,
        },
        financialOfficerSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        name: {
          'ui:placeholder': 'Ex.: Maria Silva',
          'ui:options': {
            colSpan: 2,
          },
        },
        mail: {
          'ui:placeholder': 'exemplo@email.com.br',
          'ui:widget': 'TextWidget',
        },
        phone: {
          'ui:placeholder': '(00) 00000 0000',
          'ui:options': {
            mask: 'phone',
          },
        },
        cpf: {
          'ui:placeholder': '000.000.000-00',
          'ui:options': {
            mask: 'cpf',
          },
        },
        birthDate: {
          'ui:placeholder': '00/00/0000',
        },
      },
      dentalPlan: {
        'ui:options': {
          colSpan: 2,
        },
        catalogPlanId: {
          'ui:options': {
            colSpan: 2,
          },
          'ui:placeholder': 'Selecione o plano',
          'ui:disabled': !planOptions || (planOptions && planOptions?.length === 0),
        },
        accommodationType: {
          'ui:disabled': !accommodationType,
          'ui:placeholder': accommodationType?.placeholder,
        },
        lifeAmount: {
          'ui:placeholder': 'R$ 00,00',
          'ui:options': {
            mask: 'money',
          },
        },
      },
      isLegalAge: {
        'ui:widget': 'hidden',
      },
    },
  }
}
