import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { SearchOutlined } from '@ant-design/icons'
import { useVariant } from '@unleash/proxy-client-react'
import { Skeleton } from 'antd'
import { DatePicker, Image, Select, TextInput } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { OcrDocumentInformationResponse } from '@/App/clients/OCR/mutation/fillOcrInformation'
import { DocumentDto, Entity } from '@/App/clients/healthPlansOrders/dtos'
import { useFillOrderInformation } from '@/App/clients/healthPlansOrders/mutations/fillOrderInformation'
import { useOrderInformationQuery } from '@/App/clients/healthPlansOrders/queries/orderInformation'
import { useInsurerFields } from '@/App/clients/insurerEdge/queries/getFields'
import { assembleInsurer } from '@/App/utils/assembleInsurer'
import { formatDate } from '@/App/utils/formatDate'
import { clearInputMask, toCamelCase } from '@/App/utils/string'

import { EntityType } from '../..'
import { amilIssuingAgencies } from '../../helper/amil/issuingAgencies'
import { portoIssuingAgencies } from '../../helper/porto/issuingAgencies'
import {
  FormItem,
  FormItemLabel,
  ImageInspector,
  SearchIconDiv,
  StyledFigure,
  StyledForm,
  StyledOCRView,
  StyledWrapper,
} from './style'

export interface OnFileUploadInput {
  fileContent: Blob | string
  fileContentType: string
  fileName: string
  reference?: string
}

export interface OCRDocumentsProps {
  isInspectorOpen: boolean
  setInspectorOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>
  documentUploaded?: DocumentDto
  entityData?: Entity | null
  OCRData?: OcrDocumentInformationResponse
  OCRLoading: boolean
  orderId?: string | null
  isViewOcrActivated: boolean
  insurer?: string | null
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>
  setDocumentUploaded: React.Dispatch<React.SetStateAction<DocumentDto | undefined>>
  reloadOrderData?: () => void
  isOcrProcessing: boolean
  setIsOcrProcessing: React.Dispatch<React.SetStateAction<boolean>>
  entityType: EntityType
  documentType: string
}

export const OCRDocuments: React.FC<OCRDocumentsProps> = ({
  isInspectorOpen,
  setInspectorOpen,
  documentUploaded,
  entityData,
  OCRData,
  OCRLoading,
  orderId,
  isViewOcrActivated,
  insurer,
  setVisibility,
  setDocumentUploaded,
  reloadOrderData,
  setIsOcrProcessing,
  entityType,
  documentType,
}) => {
  const [imagePreviewVisibility, setImagePreviewVisibility] = useState(false)

  const fillOrderInformationSaveMutation = useFillOrderInformation()
  const orderInformationQuery = useOrderInformationQuery({ orderId })
  const formattedInsurer = toCamelCase(assembleInsurer(insurer))
  const insurerEdge = useVariant('bliss-intranet-insurer-edge')?.payload?.value
  const insurerEdgeFlags = JSON.parse(insurerEdge ?? '{}')
  const isInsurerEdgeEnabled =
    insurerEdgeFlags[formattedInsurer ?? ''] || insurerEdgeFlags['default'] || false

  const insurerFieldsQuery = useInsurerFields({
    fieldType: 'DROPDOWN',
    insurerId: orderInformationQuery?.data?.quotation?.insurerId,
    enabled: isInsurerEdgeEnabled,
  })
  const defaultIssuingAgency = insurer === 'Amil' ? amilIssuingAgencies : portoIssuingAgencies

  const issuingAgency = isInsurerEdgeEnabled
    ? insurerFieldsQuery.data
        ?.find((field) => field.name === 'issuingAgency')
        ?.options.filter((o) => o.type.toUpperCase() === entityType)
        .map((o) => ({ label: o.intranetValue, value: o.blissValue })) || defaultIssuingAgency
    : defaultIssuingAgency

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm<Entity>({
    defaultValues: entityData ?? {},
    mode: 'onSubmit',
  })

  useEffect(() => {
    const regex = /^(?<year>\d{4})-(?<month>[a-zA-Z]{3})-(?<day>\d{1,2})$/
    if (OCRData != undefined) {
      setValue('name', OCRData.name)
      setValue('cpf', OCRData.cpfNumber)
      setValue('motherName', OCRData.motherName)
      setValue('issuingAgency', OCRData.issuerIdentityDocument)
      setValue('rg', documentType === 'CNH' ? OCRData.rgNumber : OCRData.number)
      setValue(
        'birthDate',
        formatDate(OCRData.dateBirth)?.includes('undefined') ||
          formatDate(OCRData.dateBirth)?.match(regex)
          ? null
          : formatDate(OCRData.dateBirth),
      )
    }
  }, [OCRData])

  useEffect(() => {
    if (entityData?.externalLegalRepresentative != null) {
      setValue('name', entityData?.externalLegalRepresentative.name)
      setValue('cpf', entityData?.externalLegalRepresentative.cpf)
    }
  }, [entityData?.externalLegalRepresentative])

  const fillEntityInformation = async (data: Entity) => {
    delete data.hiredPlans
    delete data.quotationPlans
    const payload =
      entityType === 'COMPANY'
        ? {
            company: {
              ...entityData,
              id: entityData?.id,
              externalLegalRepresentative: {
                id: entityData?.externalLegalRepresentative?.id,
                cpf: clearInputMask(data?.cpf) ?? null,
                name: data?.name ?? null,
                birthDate: data?.birthDate ?? null,
                rg: data?.rg ?? null,
                motherName: data?.motherName ?? null,
              },
            },
          }
        : {
            entities: [
              {
                id: entityData?.id,
                cpf: clearInputMask(data?.cpf),
                name: data?.name ?? null,
                birthDate: data?.birthDate ?? null,
                rg: data?.rg ?? null,
                motherName: data?.motherName ?? null,
                issuingAgency: data?.issuingAgency ?? null,
              },
            ],
          }

    fillOrderInformationSaveMutation.mutate({ ...payload, orderId: orderId })
    setIsOcrProcessing(true)
  }

  useEffect(() => {
    if (fillOrderInformationSaveMutation.isSuccess) {
      setVisibility(false)
      setDocumentUploaded(undefined)
      setInspectorOpen(false)
      reloadOrderData && reloadOrderData()
    }
  }, [fillOrderInformationSaveMutation.isSuccess])

  useEffect(() => {
    if (fillOrderInformationSaveMutation.isError) {
      triggerToast(false, 'Ops algo deu errado, verifique as informações enviadas!')
      setIsOcrProcessing(false)
    }
  }, [fillOrderInformationSaveMutation.isError])

  useEffect(() => {
    if (Object.keys(errors).length != 0) {
      triggerToast(false, 'Preencha os campos obrigatórios!')
    }
  }, [errors])

  const requiredMessage = 'Campo obrigatório'

  return (
    <>
      {OCRLoading && <Skeleton />}
      {(isInspectorOpen || isViewOcrActivated) && (
        <StyledWrapper>
          <StyledOCRView>
            <ImageInspector>
              <StyledFigure>
                {documentUploaded?.fileName?.split('.').pop() === 'pdf' ? (
                  <embed src={documentUploaded?.previewURL} width={450} height={380} />
                ) : (
                  <Image
                    height={'360px'}
                    src={documentUploaded?.previewURL ?? ''}
                    visible={imagePreviewVisibility && !!documentUploaded?.previewURL}
                    setVisible={setImagePreviewVisibility}
                  />
                )}
              </StyledFigure>
              <SearchIconDiv>
                {!(documentUploaded?.fileName?.split('.').pop() === 'pdf') && (
                  <SearchOutlined onClick={() => setImagePreviewVisibility(true)} />
                )}
              </SearchIconDiv>
            </ImageInspector>
          </StyledOCRView>
          <StyledForm id="OCR" onSubmit={handleSubmit(fillEntityInformation)}>
            <FormItem>
              <TextInput
                name="name"
                placeholder="Ex.: Maria Silva"
                label="Nome Completo:"
                vertical
                register={{
                  ...register('name', {
                    setValueAs: (value) => (value ? value : null),
                    required: requiredMessage,
                  }),
                }}
                error={errors.name}
              />
            </FormItem>

            <FormItem>
              <TextInput
                name="cpf"
                label="CPF*"
                placeholder="000.000.000-00"
                vertical
                mask="cpf"
                register={{
                  ...register('cpf', {
                    setValueAs: (value) => (value ? value : null),
                    required: requiredMessage,
                  }),
                }}
                error={errors.cpf}
              />
            </FormItem>

            <FormItem>
              <TextInput
                name="rg"
                label="RG*"
                placeholder="00000000"
                vertical
                register={{
                  ...register('rg', {
                    setValueAs: (value) => (value ? value : null),
                    required: requiredMessage,
                  }),
                }}
                error={errors.rg}
              />
            </FormItem>

            {(insurer === 'Amil' || insurer === 'Porto Seguro') && (
              <FormItem>
                <FormItemLabel>Órgão emissor*</FormItemLabel>
                <Select
                  name="issuingAgency"
                  control={control}
                  placeholder="Selecione o órgão emissor"
                  defaultValue={OCRData?.issuerIdentityDocument}
                  disabled={isInsurerEdgeEnabled && issuingAgency.length === 0}
                  options={issuingAgency}
                  rules={{
                    setValueAs: (value) => (value ? value : null),
                    required: requiredMessage,
                  }}
                />
              </FormItem>
            )}

            <FormItem>
              <FormItemLabel>Data Nascimento*</FormItemLabel>
              <DatePicker name="birthDate" control={control} />
            </FormItem>

            <FormItem>
              <TextInput
                name="motherName"
                label="Nome da Mãe*"
                placeholder="Ex.: Karla Silva"
                vertical
                register={{
                  ...register('motherName', {
                    setValueAs: (value) => (value ? value : null),
                    required: requiredMessage,
                  }),
                }}
                error={errors.motherName}
              />
            </FormItem>
          </StyledForm>
        </StyledWrapper>
      )}
    </>
  )
}
