import type { GetSchemaProps } from '..'
import { companyAliceSchema } from './alice'
import { companyAmilSchema } from './amil'
import { companyBradescoSchema } from './bradesco'
import { companyDefaultSchema } from './default'
import { companyGndiSchema } from './gndi'
import { companyPortoSeguroSchema } from './portoSeguro'
import { companySulamericaSchema } from './sulamerica'

const companySchemas = {
  amil: companyAmilSchema,
  gndi: companyGndiSchema,
  alice: companyAliceSchema,
  portoSeguro: companyPortoSeguroSchema,
  sulamerica: companySulamericaSchema,
  bradesco: companyBradescoSchema,
  default: companyDefaultSchema,
}

export function getCompanySchema({
  insurer,
  isOcrFilled,
  isRegisterInInsurer,
  insurerFields,
  isInsurerEdgeEnabled,
}: GetSchemaProps) {
  if (isRegisterInInsurer || !insurer) {
    return companySchemas.default({ isOcrFilled, insurerFields, isInsurerEdgeEnabled })
  }

  const schema = companySchemas[insurer] || companySchemas.default

  return schema({ isOcrFilled, insurerFields, isInsurerEdgeEnabled })
}
