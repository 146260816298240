export const portoIssuingAgencies = [
  {
    label: 'SSP',
    value: 'SSP',
  },
  {
    label: 'MAER',
    value: 'MAER',
  },
  {
    label: 'MEX',
    value: 'MEX',
  },
  {
    label: 'MMA',
    value: 'MMA',
  },
  {
    label: 'PF',
    value: 'PF',
  },
  {
    label: 'CLA',
    value: 'CLA',
  },
  {
    label: 'CRA',
    value: 'CRA',
  },
  {
    label: 'CRAS',
    value: 'CRAS',
  },
  {
    label: 'CRB',
    value: 'CRB',
  },
  {
    label: 'CRC',
    value: 'CRC',
  },
  {
    label: 'CRECI',
    value: 'CRECI',
  },
  {
    label: 'COREN',
    value: 'COREN',
  },
  {
    label: 'CREA',
    value: 'CREA',
  },
  {
    label: 'CONRE',
    value: 'CONRE',
  },
  {
    label: 'CRF',
    value: 'CRF',
  },
  {
    label: 'CREFITO',
    value: 'CREFITO',
  },
  {
    label: 'CRM',
    value: 'CRM',
  },
  {
    label: 'CRMV',
    value: 'CRMV',
  },
  {
    label: 'CRN',
    value: 'CRN',
  },
  {
    label: 'CRO',
    value: 'CRO',
  },
  {
    label: 'CRPRE',
    value: 'CRPRE',
  },
  {
    label: 'CRP',
    value: 'CRP',
  },
  {
    label: 'CRQ',
    value: 'CRQ',
  },
  {
    label: 'CORE',
    value: 'CORE',
  },
  {
    label: 'OMB',
    value: 'OMB',
  },
  {
    label: 'OAB',
    value: 'OAB',
  },
]
