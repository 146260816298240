import { QueryFunctionContext, queryOptions, useQuery } from '@tanstack/react-query'

import { api } from '../../http'

type CountryOption = {
  label: string
  value: string
}

async function getCountries({ signal }: QueryFunctionContext) {
  const response = await api.get(
    `https://servicodados.ibge.gov.br/api/v1/paises/BR|UY|AR|CO|CL|PE|PY|VE|LB|KR|BO`,
    {
      signal,
    },
  )
  return response.data
}

export function getCountriesQueryOptions() {
  const ONE_MONTH_IN_MS = 30 * 24 * 60 * 60 * 1000
  return queryOptions({
    queryKey: ['countries'] as const,
    queryFn: async (params) => getCountries({ ...params }),
    refetchOnWindowFocus: false,
    staleTime: ONE_MONTH_IN_MS,
    select: (data) => {
      const countrysOptions: Array<CountryOption> = []

      for (const country of data) {
        countrysOptions.push({
          label: country.nome.abreviado,
          value: country.nome.abreviado,
        })
      }
      return countrysOptions
    },
  })
}

export const useGetCountriesQuery = () => {
  return useQuery(getCountriesQueryOptions())
}
