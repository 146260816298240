export const gndiKinships = [
  { label: 'Cônjuge', value: 'SPOUSE' },
  { label: 'Cunhado', value: 'BROTHER-IN-LAW' },
  { label: 'Enteado', value: 'STEPCHILDREN' },
  { label: 'Filho(a)', value: 'CHILDREN' },
  { label: 'Genro', value: 'SON-IN-LAW' },
  { label: 'Irmão', value: 'BROTHER' },
  { label: 'Madastra', value: 'STEPMOTHER' },
  { label: 'Neto', value: 'GRANDSON' },
  { label: 'Pai/Mãe', value: 'FATHER' },
  { label: 'Sobrinho', value: 'NEPHEWS' },
  { label: 'Sogro', value: 'FATHER-IN-LAW' },
  { label: 'Tio', value: 'UNCLE' },
]
