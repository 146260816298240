import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'
import { insurers } from 'design-system/insurers'

import { kinships } from '@/App/helpers/kinships'

import type { GetSchemaProps } from '..'
import { defaultGender } from '../../helper/default/gender'
import { defaultMaritalStatus } from '../../helper/default/maritalStatus'
import { transformCommonOptionToInsurerEdgeOptions } from '../../helper/helper'

export function dependentDefaultSchema({
  insurerFields,
  isInsurerEdgeEnabled,
}: GetSchemaProps): DynamicFormSchemaProps {
  const maritalStatus = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'maritalStatus')
    : transformCommonOptionToInsurerEdgeOptions({
        options: defaultMaritalStatus,
        title: 'Estado Civil*',
        type: 'Dependent',
        placeholder: 'Selecione o estado civil',
      })
  const gender = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'gender')
    : transformCommonOptionToInsurerEdgeOptions({
        options: defaultGender,
        title: 'Gênero*',
        type: 'Dependent',
        placeholder: 'Selecione o gênero',
      })
  const holderKinship = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'holderKinship')
    : transformCommonOptionToInsurerEdgeOptions({
        options: kinships,
        title: 'Parentesco*',
        type: 'Dependent',
        placeholder: 'Selecione o parentesco',
      })
  const activeHiredPlanInsurer = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'activeHiredPlanInsurer')
    : transformCommonOptionToInsurerEdgeOptions({
        options: insurers,
        title: 'Operadora*',
        type: 'Dependent',
        placeholder: 'Selecione a Operadora',
      })

  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          title: 'Nome Completo',
        },
        phone: {
          type: 'string',
          title: 'Telefone',
        },
        mail: {
          type: 'string',
          title: 'E-mail',
        },
        maritalStatus: {
          type: 'string',
          title: maritalStatus?.title,
          oneOf: maritalStatus?.options
            .filter((o) => o.type === 'Dependent')
            .map((option) => ({
              title: option.intranetValue,
              const: option.blissValue,
            })),
        },
        gender: {
          type: 'string',
          title: gender?.title,
          oneOf: gender?.options
            .filter((o) => o.type === 'Dependent')
            .map((option) => ({
              title: option.intranetValue,
              const: option.blissValue,
            })),
        },
        birthDate: {
          type: 'string',
          title: 'Data Nascimento',
          format: 'date',
        },
        rg: {
          type: 'string',
          title: 'RG',
        },
        cpf: {
          type: 'string',
          title: 'CPF',
        },
        motherName: {
          type: 'string',
          title: 'Nome da Mãe',
        },
        holderKinship: {
          type: 'string',
          title: holderKinship?.title,
          oneOf: holderKinship?.options
            .filter((o) => o.type === 'Dependent')
            .map((option) => ({
              title: option.intranetValue,
              const: option.blissValue,
            })),
        },
        isAdministrativeLife: {
          type: ['boolean', 'null'],
          title: 'É uma vida administrativa?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: null,
        },
        hasActiveHiredPlan: {
          type: 'boolean',
          title: 'Possui plano?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: false,
        },
      },
      dependencies: {
        hasActiveHiredPlan: {
          oneOf: [
            {
              properties: {
                hasActiveHiredPlan: {
                  enum: [false],
                },
              },
            },
            {
              properties: {
                hasActiveHiredPlan: {
                  enum: [true],
                },
                activeHiredPlanInsurer: {
                  type: ['string', 'null'],
                  title: activeHiredPlanInsurer?.title,
                  oneOf: activeHiredPlanInsurer?.options
                    .filter((o) => o.type === 'Dependent')
                    .map((option) => ({
                      title: option.intranetValue,
                      const: option.blissValue,
                    })),
                },
              },
            },
          ],
        },
      },
    },
    uiSchema: {
      name: {
        'ui:placeholder': 'Ex.: Maria Silva',
      },
      phone: {
        'ui:placeholder': '(00) 00000 0000',
        'ui:options': {
          mask: 'phone',
        },
      },
      mail: {
        'ui:placeholder': 'exemplo@email.com.br',
        'ui:widget': 'TextWidget',
      },
      maritalStatus: {
        'ui:placeholder': maritalStatus?.placeholder,
      },
      gender: {
        'ui:placeholder': gender?.placeholder,
      },
      birthDate: {
        'ui:placeholder': '00/00/0000',
      },
      rg: {
        'ui:placeholder': '000000000',
      },
      cpf: {
        'ui:placeholder': '000.000.000-00',
      },
      motherName: {
        'ui:placeholder': 'Ex.: Ana Silva',
      },
      holderKinship: {
        'ui:placeholder': holderKinship?.placeholder,
      },
      isAdministrativeLife: {
        'ui:widget': 'radio',
      },
      hasActiveHiredPlan: {
        'ui:widget': 'radio',
      },
      activeHiredPlanInsurer: {
        'ui:placeholder': activeHiredPlanInsurer?.placeholder,
      },
    },
  }
}
