import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'

import type { GetSchemaProps } from '../..'
import { gndiGender } from '../../../helper/gndi/gender'
import { gndiKinships } from '../../../helper/gndi/kinships'
import { gndiMaritalStatus } from '../../../helper/gndi/maritalStatus'
import { transformCommonOptionToInsurerEdgeOptions } from '../../../helper/helper'

export function dependentGndiDentalSchema({
  insurerFields,
  isInsurerEdgeEnabled,
}: GetSchemaProps): DynamicFormSchemaProps {
  const maritalStatus = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'maritalStatus')
    : transformCommonOptionToInsurerEdgeOptions({
        options: gndiMaritalStatus,
        title: 'Estado Civil*',
        type: 'Dependent',
        placeholder: 'Selecione o estado civil',
      })
  const gender = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'gender')
    : transformCommonOptionToInsurerEdgeOptions({
        options: gndiGender,
        title: 'Gênero*',
        type: 'Dependent',
        placeholder: 'Selecione o gênero',
      })
  const gracePeriodReduction = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'gracePeriodReduction')
    : transformCommonOptionToInsurerEdgeOptions({
        options: [
          { label: 'Carência Contratual (Padrão)', value: 'Carência Contratual (Padrão)' },
          {
            label: 'Com aproveitamento de carências',
            value: 'Com aproveitamento de carências',
          },
        ],
        title: 'Redução de Carência*',
        type: 'Dependent',
        placeholder: 'Selecione a carência',
      })
  const holderKinship = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'holderKinship')
    : transformCommonOptionToInsurerEdgeOptions({
        options: gndiKinships,
        title: 'Grau de Parentesco*',
        type: 'Dependent',
        placeholder: 'Selecione a Grau de Parentesco',
      })

  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        name: {
          type: ['string', 'null'],
          title: 'Nome Completo',
        },
        isPartner: {
          type: 'boolean',
          title: 'Sócio/Cônjuge de sócio da empresa?',
          default: false,
        },
        maritalStatus: {
          type: ['string', 'null'],
          title: maritalStatus?.title,
          oneOf:
            maritalStatus?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        gender: {
          type: ['string', 'null'],
          title: gender?.title,
          oneOf:
            gender?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        cpf: {
          type: ['string', 'null'],
          title: 'CPF*',
        },
        rg: {
          type: ['string', 'null'],
          title: 'RG*',
        },
        birthDate: {
          type: ['string', 'null'],
          title: 'Data de Nascimento',
          format: 'date',
        },
        motherName: {
          type: ['string', 'null'],
          title: 'Nome da Mãe',
        },
        holderKinship: {
          type: ['string', 'null'],
          title: holderKinship?.title,
          oneOf:
            holderKinship?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        phone: {
          type: ['string', 'null'],
          title: 'Telefone*',
        },
        gracePeriodReduction: {
          type: ['string', 'null'],
          title: gracePeriodReduction?.title,
          oneOf:
            gracePeriodReduction?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        isAdministrativeLife: {
          type: ['boolean', 'null'],
          title: 'É uma vida administrativa?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: null,
        },
        dentalPlan: {
          type: 'object',
          properties: {
            subtitle: {
              type: 'string',
              title: 'Dados Complementares (Cadastro Sisweb)',
            },
            lifeAmount: {
              type: ['string', 'null'],
              title: 'Valor Vida Dental*',
            },
          },
        },
      },
      if: {
        properties: {
          maritalStatus: { const: 'MARRIED' },
        },
      },
      then: {
        properties: {
          marriageDate: {
            type: ['string', 'null'],
            title: 'Data do casamento*',
            format: 'date',
          },
        },
      },
    },
    uiSchema: {
      name: {
        'ui:placeholder': 'Ex.: Maria da Silva',
      },
      maritalStatus: {
        'ui:placeholder': 'Selecione o estado civil',
      },
      gender: {
        'ui:placeholder': 'Selecione o gênero',
      },
      cpf: {
        'ui:placeholder': '000.000.000-00',
        'ui:options': {
          mask: 'cpf',
        },
      },
      rg: {
        'ui:placeholder': '000000000',
      },
      birthDate: {
        'ui:placeholder': '00/00/0000',
      },
      motherName: {
        'ui:placeholder': 'Ex.: Ana da Silva',
      },
      holderKinship: {
        'ui:placeholder': 'Selecione o grau de parentesco',
      },
      phone: {
        'ui:placeholder': '(00) 00000 0000',
        'ui:options': {
          mask: 'phone',
        },
      },
      gracePeriodReduction: {
        'ui:placeholder': 'Selecione a carência',
      },
      isAdministrativeLife: {
        'ui:widget': 'radio',
      },
      marriageDate: {
        'ui:placeholder': '00/00/0000',
      },
      dentalPlan: {
        'ui:options': {
          colSpan: 2,
        },
        subtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        lifeAmount: {
          'ui:placeholder': 'R$ 00,00',
          'ui:options': {
            mask: 'money',
          },
        },
      },
    },
  }
}
