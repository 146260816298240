export const aliceGracePeriodReduction = [
  {
    label: 'Possui',
    value: 'Possui',
  },
  {
    label: 'Não possui',
    value: 'Não possui',
  },
]
