import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'
import { insurers } from 'design-system/insurers'

import type { GetSchemaProps } from '../..'
import { amilContractTypes } from '../../../helper/amil/contractType'
import { defaultAccommodationType } from '../../../helper/default/accommodationType'
import { defaultGender } from '../../../helper/default/gender'
import { defaultMaritalStatus } from '../../../helper/default/maritalStatus'
import { transformCommonOptionToInsurerEdgeOptions } from '../../../helper/helper'

export function holderDefaultHealthSchema({
  planOptions,
  isOcrFilled,
  genderPrediction,
  enableHiredFields,
  insurerFields,
  isInsurerEdgeEnabled,
}: GetSchemaProps): DynamicFormSchemaProps {
  const maritalStatus = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'maritalStatus')
    : transformCommonOptionToInsurerEdgeOptions({
        options: defaultMaritalStatus,
        title: 'Estado Civil*',
        type: 'Holder',
        placeholder: 'Selecione o estado civil',
      })
  const gender = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'gender')
    : transformCommonOptionToInsurerEdgeOptions({
        options: defaultGender,
        title: 'Gênero*',
        type: 'Holder',
        placeholder: 'Selecione o gênero',
      })
  const accommodationType = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'accommodationType')
    : transformCommonOptionToInsurerEdgeOptions({
        options: defaultAccommodationType,
        title: 'Acomodação*',
        type: 'Holder',
        placeholder: 'Selecione a Acomodação',
      })
  const activeHiredPlanInsurer = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'activeHiredPlanInsurer')
    : transformCommonOptionToInsurerEdgeOptions({
        options: insurers,
        title: 'Operadora*',
        type: 'Holder',
        placeholder: 'Selecione a Operadora',
      })
  const contractType = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'contractTypes')
    : transformCommonOptionToInsurerEdgeOptions({
        options: amilContractTypes,
        title: 'Tipo de Contratação*',
        type: 'Holder',
        placeholder: 'Selecione o Tipo de Contratação',
      })

  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        name: {
          type: ['string', 'null'],
          title: 'Nome Completo*',
        },
        phone: {
          type: ['string', 'null'],
          title: 'Telefone*',
        },
        mail: {
          type: ['string', 'null'],
          title: 'E-mail',
          format: 'email',
        },
        maritalStatus: {
          type: ['string', 'null'],
          title: maritalStatus?.title,
          oneOf: maritalStatus?.options
            .filter((o) => o.type === 'Holder')
            .map((option) => ({
              title: option.intranetValue,
              const: option.blissValue,
            })),
          default: null,
        },
        gender: {
          type: ['string', 'null'],
          title: gender?.title,
          oneOf: gender?.options
            .filter((o) => o.type === 'Holder')
            .map((option) => ({
              title: option.intranetValue,
              const: option.blissValue,
            })),
          default: genderPrediction?.toUpperCase() ?? null,
        },
        birthDate: {
          type: ['string', 'null'],
          title: 'Data de Nascimento*',
          format: 'date',
        },
        cpf: {
          type: ['string', 'null'],
          title: 'CPF*',
        },
        rg: {
          type: ['string', 'null'],
          title: 'RG*',
        },
        motherName: {
          type: ['string', 'null'],
          title: 'Nome da mãe*',
        },
        isAdministrativeLife: {
          type: ['boolean', 'null'],
          title: 'É uma vida administrativa?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: null,
        },
        address: {
          type: 'object',
          properties: {
            zipCode: {
              type: ['string', 'null'],
              title: 'CEP*',
            },
            entityAddress: {
              type: ['string', 'null'],
              title: 'Endereço*',
            },
            number: {
              type: ['string', 'null'],
              title: 'Número*',
            },
            complement: {
              type: ['string', 'null'],
              title: 'Complemento',
            },
            neighborhood: {
              type: ['string', 'null'],
              title: 'Bairro',
            },
            city: {
              type: ['string', 'null'],
              title: 'Cidade',
            },
            state: {
              type: ['string', 'null'],
              title: 'Estado',
            },
          },
        },
        healthPlan: {
          type: 'object',
          properties: {
            catalogPlanId: {
              type: ['string', 'null'],
              title: 'Plano Médico*',
              oneOf:
                planOptions && planOptions?.length > 0
                  ? planOptions.map((plan) => ({
                      title: plan.label,
                      const: plan.value,
                    }))
                  : [{ title: 'Selecione o plano', const: '' }],
            },
            accommodationType: {
              type: ['string', 'null'],
              title: accommodationType?.title,
              oneOf: accommodationType?.options
                .filter((o) => o.type === 'Holder')
                .map((option) => ({
                  title: option.intranetValue,
                  const: option.blissValue,
                })),
            },
            lifeAmount: {
              type: ['string', 'null'],
              title: 'Valor Vida Saúde*',
            },
          },
        },
        hiredHealthPlan: {
          type: 'object',
          properties: {
            isActive: {
              type: 'boolean',
              title: 'Possui plano?',
              oneOf: [
                { title: 'Sim', const: true },
                { title: 'Não', const: false },
              ],
              default: false,
            },
          },
          dependencies: {
            isActive: {
              oneOf: [
                {
                  properties: {
                    isActive: {
                      enum: [false],
                    },
                  },
                },
                {
                  properties: {
                    isActive: {
                      enum: [true],
                    },
                    activeHiredPlanInsurer: {
                      type: ['string', 'null'],
                      title: activeHiredPlanInsurer?.title,
                      oneOf: activeHiredPlanInsurer?.options
                        .filter((o) => o.type === 'Holder')
                        .map((option) => ({
                          title: option.intranetValue,
                          const: option.blissValue,
                        })),
                    },
                  },
                },
              ],
            },
          },
        },
        isLegalAge: {
          type: 'boolean',
        },
        insurer: {
          type: 'string',
        },
      },
      dependencies: {
        isLegalAge: {
          oneOf: [
            {
              properties: {
                isLegalAge: {
                  enum: [true],
                },
              },
            },
            {
              properties: {
                isLegalAge: {
                  enum: [false],
                },
                financialOfficer: {
                  type: 'object',
                  properties: {
                    financialOfficerSubtitle: {
                      type: 'string',
                      title: 'Responsável Financeiro',
                    },
                    name: {
                      type: 'string',
                      title: 'Nome Completo',
                    },
                    mail: {
                      type: 'string',
                      title: 'E-mail',
                      format: 'email',
                    },
                    phone: {
                      type: 'string',
                      title: 'Telefone',
                    },
                    cpf: {
                      type: 'string',
                      title: 'CPF*',
                    },
                    birthDate: {
                      type: 'string',
                      title: 'Data Nascimento',
                      format: 'date',
                    },
                  },
                },
              },
            },
          ],
        },
        insurer: {
          oneOf: [
            {
              properties: {
                insurer: {
                  enum: ['Amil'],
                },
                contractType: {
                  type: ['string', 'null'],
                  title: contractType?.title,
                  oneOf: contractType?.options
                    .filter((o) => o.type === 'Holder')
                    .map((option) => ({
                      title: option.intranetValue,
                      const: option.blissValue,
                    })),
                },
                contractDate: {
                  type: ['string', 'null'],
                  title: 'Data de Contratação*',
                  format: 'date',
                },
                height: {
                  type: ['string', 'null'],
                  title: 'Altura (m)',
                },
                weight: {
                  type: ['string', 'null'],
                  title: 'Peso (kg)',
                },
              },
            },
          ],
        },
      },
    },
    uiSchema: {
      'ui:order': [
        'name',
        'phone',
        'mail',
        'maritalStatus',
        'gender',
        'birthDate',
        'cpf',
        'rg',
        'motherName',
        'isAdministrativeLife',
        'contractType',
        'contractDate',
        'height',
        'weight',
        '*',
      ],
      name: {
        'ui:placeholder': 'Ex.: Maria da Silva',
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      phone: {
        'ui:placeholder': '(00) 00000-0000',
        'ui:options': {
          mask: 'phone',
        },
      },
      mail: {
        'ui:placeholder': 'exemplo@email.com',
        'ui:widget': 'TextWidget',
      },
      maritalStatus: {
        'ui:placeholder': maritalStatus?.placeholder,
      },
      gender: {
        'ui:placeholder': gender?.placeholder,
      },
      birthDate: {
        'ui:placeholder': '00/00/0000',
      },
      address: {
        'ui:options': {
          colSpan: 2,
        },
        zipCode: {
          'ui:placeholder': '00000-000',
          'ui:disabled': isOcrFilled,
          'ui:options': {
            mask: 'cep',
          },
        },
        entityAddress: {
          'ui:placeholder': 'Buscar endereço',
          'ui:disabled': isOcrFilled,
          'ui:widget': 'AutoCompleteWidget',
        },
        number: {
          'ui:placeholder': '000',
          'ui:disabled': isOcrFilled,
        },
        complement: {
          'ui:placeholder': 'Ex.: Apto 123',
        },
        neighborhood: {
          'ui:placeholder': 'Ex.: Pinheiros',
          'ui:disabled': isOcrFilled,
        },
        city: {
          'ui:placeholder': 'Ex.: São Paulo',
          'ui:disabled': isOcrFilled,
        },
        state: {
          'ui:placeholder': 'Ex.: SP',
          'ui:disabled': isOcrFilled,
        },
      },
      cpf: {
        'ui:placeholder': '000.000.000-00',
        'ui:options': {
          mask: 'cpf',
        },
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      rg: {
        'ui:placeholder': '000000000',
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      motherName: {
        'ui:placeholder': 'Ex.: Ana da Silva',
        'ui:disabled': isOcrFilled,
        'ui:widget': 'OCRDisabledTextInputWidget',
      },
      isAdministrativeLife: {
        'ui:widget': 'radio',
      },
      contractType: {
        'ui:placeholder': contractType?.placeholder,
        'ui:widget': enableHiredFields ? 'SelectWidget' : 'hidden',
      },
      contractDate: {
        'ui:placeholder': '00/00/0000',
        'ui:widget': enableHiredFields ? 'DateWidget' : 'hidden',
      },
      height: {
        'ui:placeholder': 'Ex.: 1.76m',
        'ui:options': {
          mask: 'height',
        },
        'ui:widget': enableHiredFields ? 'TextWidget' : 'hidden',
      },
      weight: {
        'ui:placeholder': 'Ex.: 86.60kg',
        'ui:options': {
          mask: 'weight',
        },
        'ui:widget': enableHiredFields ? 'TextWidget' : 'hidden',
      },
      healthPlan: {
        'ui:options': {
          colSpan: 2,
        },
        catalogPlanId: {
          'ui:placeholder': 'Selecione o plano',
          'ui:disabled': !planOptions || (planOptions && planOptions?.length === 0),
          'ui:options': {
            colSpan: 2,
          },
        },
        accommodationType: {
          'ui:placeholder': accommodationType?.placeholder,
        },
        lifeAmount: {
          'ui:placeholder': 'R$ 00,00',
          'ui:options': {
            mask: 'money',
          },
        },
      },
      hiredHealthPlan: {
        'ui:options': {
          colSpan: 2,
        },
        isActive: {
          'ui:widget': 'radio',
        },
        activeHiredPlanInsurer: {
          'ui:placeholder': activeHiredPlanInsurer?.placeholder,
        },
      },
      emptyColumn2: {
        'ui:widget': 'hidden',
      },
      financialOfficer: {
        'ui:options': {
          colSpan: 2,
        },
        financialOfficerSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        name: {
          'ui:placeholder': 'Ex.: Maria Silva',
          'ui:options': {
            colSpan: 2,
          },
        },
        mail: {
          'ui:placeholder': 'exemplo@email.com.br',
          'ui:widget': 'TextWidget',
        },
        phone: {
          'ui:placeholder': '(00) 00000 0000',
          'ui:options': {
            mask: 'phone',
          },
        },
        cpf: {
          'ui:placeholder': '000.000.000-00',
          'ui:options': {
            mask: 'cpf',
          },
        },
        birthDate: {
          'ui:placeholder': '00/00/0000',
        },
      },
      isLegalAge: {
        'ui:widget': 'hidden',
      },
      insurer: {
        'ui:widget': 'hidden',
      },
    },
  }
}
