import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'

import type { GetSchemaProps } from '../..'

export function dependentPortoSeguroDentalSchema({
  insurerFields,
}: GetSchemaProps): DynamicFormSchemaProps {
  const maritalStatus = insurerFields?.find((field) => field.name === 'maritalStatus')

  const gender = insurerFields?.find((field) => field.name === 'gender')

  const issuingAgency = insurerFields?.find((field) => field.name === 'issuingAgency')

  const activeHiredPlanInsurer = insurerFields?.find(
    (field) => field.name === 'activeHiredPlanInsurer',
  )

  const holderKinship = insurerFields?.find((field) => field.name === 'holderKinship')

  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        name: {
          type: ['string', 'null'],
          title: 'Nome Completo*',
        },
        holderKinship: {
          type: ['string', 'null'],
          title: holderKinship?.title,
          oneOf:
            holderKinship?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        maritalStatus: {
          type: ['string', 'null'],
          title: maritalStatus?.title,
          oneOf:
            maritalStatus?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        gender: {
          type: ['string', 'null'],
          title: gender?.title,
          oneOf:
            gender?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        cpf: {
          type: ['string', 'null'],
          title: 'CPF*',
        },
        rg: {
          type: ['string', 'null'],
          title: 'RG*',
        },
        issuingAgency: {
          type: ['string', 'null'],
          title: issuingAgency?.title,
          oneOf:
            issuingAgency?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        issuingDate: {
          type: ['string', 'null'],
          title: 'Data de Expedição*',
          format: 'date',
        },
        birthDate: {
          type: ['string', 'null'],
          title: 'Data de Nascimento*',
          format: 'date',
        },
        motherName: {
          type: ['string', 'null'],
          title: 'Nome da Mãe*',
        },
        isAdministrativeLife: {
          type: ['boolean', 'null'],
          title: 'É uma vida administrativa?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: null,
        },
        hiredDentalPlan: {
          type: 'object',
          properties: {
            isActive: {
              type: 'boolean',
              title: 'Possui plano médico?',
              oneOf: [
                { title: 'Sim', const: true },
                { title: 'Não', const: false },
              ],
              default: false,
            },
          },
          dependencies: {
            isActive: {
              oneOf: [
                {
                  properties: {
                    isActive: {
                      enum: [false],
                    },
                  },
                },
                {
                  properties: {
                    isActive: {
                      enum: [true],
                    },
                    hiredHealthSubtitle: {
                      type: ['string', 'null'],
                      title: 'Dados do Plano Médico Atual',
                    },
                    activeHiredPlanInsurer: {
                      type: ['string', 'null'],
                      title: activeHiredPlanInsurer?.title,
                      oneOf:
                        activeHiredPlanInsurer?.options
                          .filter((o) => o.type === 'Dependent')
                          .map((option) => ({
                            title: option.intranetValue,
                            const: option.blissValue,
                          })) ?? [],
                    },
                    hiredAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data de Início',
                    },
                    lastPaymentAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data do Último Pagamento',
                    },
                  },
                },
              ],
            },
          },
        },
        dentalPlan: {
          type: 'object',
          properties: {
            subtitle: {
              type: 'string',
              title: 'Dados Complementares (Cadastro Sisweb)',
            },
            lifeAmount: {
              type: ['string', 'null'],
              title: 'Valor Vida Dental*',
            },
          },
        },
      },
      if: {
        properties: {
          maritalStatus: { const: 'MARRIED' },
        },
      },
      then: {
        properties: {
          marriageDate: {
            type: ['string', 'null'],
            title: 'Data do casamento*',
            format: 'date',
          },
        },
      },
    },
    uiSchema: {
      'ui:order': ['name', 'holderKinship', 'maritalStatus', 'marriageDate', '*'],
      name: {
        'ui:placeholder': 'Ex.: Maria da Silva',
      },
      maritalStatus: {
        'ui:disabled': !maritalStatus,
        'ui:placeholder': maritalStatus?.placeholder,
      },
      marriageDate: {
        'ui:placeholder': '00/00/0000',
      },
      holderKinship: {
        'ui:disabled': !holderKinship,
        'ui:placeholder': holderKinship?.placeholder,
      },
      gender: {
        'ui:disabled': !gender,
        'ui:placeholder': gender?.placeholder,
      },
      cpf: {
        'ui:placeholder': '000.000.000-00',
        'ui:options': {
          mask: 'cpf',
        },
      },
      rg: {
        'ui:placeholder': '000000000',
      },
      issuingAgency: {
        'ui:disabled': !issuingAgency,
        'ui:placeholder': issuingAgency?.placeholder,
      },
      issuingDate: {
        'ui:placeholder': '00/00/0000',
      },
      birthDate: {
        'ui:placeholder': '00/00/0000',
      },
      motherName: {
        'ui:placeholder': 'Ex.: Ana da Silva',
      },
      isAdministrativeLife: {
        'ui:widget': 'radio',
      },
      hiredDentalPlan: {
        'ui:options': {
          colSpan: 2,
        },
        isActive: {
          'ui:widget': 'radio',
          'ui:options': {
            colSpan: 2,
          },
        },
        hiredHealthSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        activeHiredPlanInsurer: {
          'ui:disabled': !activeHiredPlanInsurer,
          'ui:placeholder': activeHiredPlanInsurer?.placeholder,
        },
        hiredAt: {
          'ui:placeholder': '00/00/0000',
        },
        lastPaymentAt: {
          'ui:placeholder': '00/00/0000',
        },
      },
      dentalPlan: {
        'ui:options': {
          colSpan: 2,
        },
        subtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        lifeAmount: {
          'ui:placeholder': 'R$ 00,00',
          'ui:options': {
            mask: 'money',
          },
        },
      },
    },
  }
}
