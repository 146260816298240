import { WarningFilled } from '@ant-design/icons'
import { colors } from '@design-system/styles/theme'

import { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'

import { ErrorStyled } from '../style'

interface GndiFormQuotationProps {
  orderData: GetOrderInformationBody
  validateOrderDispatch: boolean | undefined
}

export const GndiViewForm = ({ orderData, validateOrderDispatch }: GndiFormQuotationProps) => {
  return (
    <>
      <li>Coparticipação*</li>
      <p>
        {orderData?.quotation?.isCoparticipation === true ? (
          'Completa'
        ) : orderData?.quotation?.isCoparticipation == null && validateOrderDispatch ? (
          <ErrorStyled>
            <WarningFilled style={{ color: colors.red }} />
            Dado obrigatório
          </ErrorStyled>
        ) : (
          'Parcial'
        )}
      </p>

      <li>Estado Beneficiários (Maioria)*</li>
      <p>
        {orderData?.quotation?.state ? (
          orderData?.quotation?.state
        ) : validateOrderDispatch ? (
          <ErrorStyled>
            <WarningFilled style={{ color: colors.red }} />
            Dado obrigatório
          </ErrorStyled>
        ) : (
          '-'
        )}
      </p>

      <li>Cidade Beneficiários (Maioria)*</li>
      <p>
        {orderData?.quotation?.city ? (
          orderData?.quotation?.city
        ) : validateOrderDispatch ? (
          <ErrorStyled>
            <WarningFilled style={{ color: colors.red }} />
            Dado obrigatório
          </ErrorStyled>
        ) : (
          '-'
        )}
      </p>

      <li>Vencimento Boleto*</li>
      <p>
        {orderData?.quotation?.billDueDate ? (
          orderData?.quotation?.billDueDate
        ) : validateOrderDispatch ? (
          <ErrorStyled>
            <WarningFilled style={{ color: colors.red }} />
            Dado obrigatório
          </ErrorStyled>
        ) : (
          '-'
        )}
      </p>
    </>
  )
}
