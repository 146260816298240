import { DynamicFormSchemaProps } from 'design-system/DynamicForm/DynamicForm'

import type { GetSchemaProps } from '../..'
import { bradescoGender } from '../../../helper/bradesco/gender'
import { insurersBradesco } from '../../../helper/bradesco/insurers'
import { kinshipsBradesco } from '../../../helper/bradesco/kinships'
import { bradescoMaritalStatus } from '../../../helper/bradesco/maritalStatus'
import { modalitiesBradesco } from '../../../helper/bradesco/modalities'
import { personContractTypesBradesco } from '../../../helper/bradesco/personContractTypes'
import { transformCommonOptionToInsurerEdgeOptions } from '../../../helper/helper'

export function dependentBradescoHealthSchema({
  insurerFields,
  isInsurerEdgeEnabled,
}: GetSchemaProps): DynamicFormSchemaProps {
  const maritalStatus = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'maritalStatus')
    : transformCommonOptionToInsurerEdgeOptions({
        options: bradescoMaritalStatus,
        title: 'Estado Civil*',
        type: 'Dependent',
        placeholder: 'Selecione o estado civil',
      })
  const gender = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'gender')
    : transformCommonOptionToInsurerEdgeOptions({
        options: bradescoGender,
        title: 'Gênero*',
        type: 'Dependent',
        placeholder: 'Selecione o gênero',
      })
  const contractType = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'contractType')
    : transformCommonOptionToInsurerEdgeOptions({
        options: personContractTypesBradesco,
        title: 'Tipo de Contratação*',
        type: 'Dependent',
        placeholder: 'Selecione o tipo de contratação',
      })
  const activeHiredPlanInsurer = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'activeHiredPlanInsurer')
    : transformCommonOptionToInsurerEdgeOptions({
        options: insurersBradesco,
        title: 'Operadora Atual*',
        type: 'Dependent',
        placeholder: 'Selecione a Operadora Atual',
      })
  const modality = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'modality')
    : transformCommonOptionToInsurerEdgeOptions({
        options: modalitiesBradesco,
        title: 'Modalidade*',
        type: 'Dependent',
        placeholder: 'Selecione a Modalidade',
      })
  const holderKinship = isInsurerEdgeEnabled
    ? insurerFields?.find((field) => field.name === 'holderKinship')
    : transformCommonOptionToInsurerEdgeOptions({
        options: kinshipsBradesco,
        title: 'Grau de Parentesco*',
        type: 'Dependent',
        placeholder: 'Selecione a Grau de Parentesco',
      })

  return {
    formContext: {
      hint: '*Campos de preenchimento obrigatório apenas para a emissão automática e/ou cadastro no sisweb',
    },
    schema: {
      type: 'object',
      properties: {
        name: {
          type: ['string', 'null'],
          title: 'Nome Completo*',
        },
        holderKinship: {
          type: ['string', 'null'],
          title: holderKinship?.title,
          oneOf:
            holderKinship?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        maritalStatus: {
          type: ['string', 'null'],
          title: maritalStatus?.title,
          oneOf:
            maritalStatus?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        gender: {
          type: ['string', 'null'],
          title: gender?.title,
          oneOf:
            gender?.options
              .filter((o) => o.type === 'Dependent')
              .map((option) => ({
                title: option.intranetValue,
                const: option.blissValue,
              })) ?? [],
        },
        cpf: {
          type: ['string', 'null'],
          title: 'CPF*',
        },
        rg: {
          type: ['string', 'null'],
          title: 'RG*',
        },
        birthDate: {
          type: ['string', 'null'],
          title: 'Data de Nascimento*',
          format: 'date',
        },
        motherName: {
          type: ['string', 'null'],
          title: 'Nome da Mãe*',
        },
        isAdministrativeLife: {
          type: ['boolean', 'null'],
          title: 'É uma vida administrativa?',
          oneOf: [
            { title: 'Sim', const: true },
            { title: 'Não', const: false },
          ],
          default: null,
        },
        hiredHealthPlan: {
          type: 'object',
          properties: {
            isActive: {
              type: 'boolean',
              title: 'Possui plano médico?',
              oneOf: [
                { title: 'Sim', const: true },
                { title: 'Não', const: false },
              ],
              default: false,
            },
          },
          dependencies: {
            isActive: {
              oneOf: [
                {
                  properties: {
                    isActive: {
                      enum: [false],
                    },
                  },
                },
                {
                  properties: {
                    isActive: {
                      enum: [true],
                    },
                    hiredHealthSubtitle: {
                      type: ['string', 'null'],
                      title: 'Dados do Plano Médico Atual',
                    },
                    registrationNumber: {
                      type: ['string', 'null'],
                      title: 'Matrícula',
                    },
                    activeHiredPlanInsurer: {
                      type: ['string', 'null'],
                      title: activeHiredPlanInsurer?.title,
                      oneOf:
                        activeHiredPlanInsurer?.options
                          .filter((o) => o.type === 'Dependent')
                          .map((option) => ({
                            title: option.intranetValue,
                            const: option.blissValue,
                          })) ?? [],
                    },
                    contractType: {
                      type: ['string', 'null'],
                      title: contractType?.title,
                      oneOf:
                        contractType?.options
                          .filter((o) => o.type === 'Dependent')
                          .map((option) => ({
                            title: option.intranetValue,
                            const: option.blissValue,
                          })) ?? [],
                    },
                    hiredAt: {
                      type: ['string', 'null'],
                      format: 'date',
                      title: 'Data de Início*',
                    },
                    modality: {
                      type: ['string', 'null'],
                      title: modality?.title,
                      oneOf:
                        modality?.options
                          .filter((o) => o.type === 'Dependent')
                          .map((option) => ({
                            title: option.intranetValue,
                            const: option.blissValue,
                          })) ?? [],
                    },
                  },
                },
              ],
            },
          },
        },
      },
    },
    uiSchema: {
      name: {
        'ui:placeholder': 'Ex.: Maria da Silva',
      },
      maritalStatus: {
        'ui:placeholder': 'Selecione o estado civil',
      },
      holderKinship: {
        'ui:placeholder': 'Selecione o grau de parentesco',
      },
      gender: {
        'ui:placeholder': 'Selecione o gênero',
      },
      cpf: {
        'ui:placeholder': '000.000.000-00',
        'ui:options': {
          mask: 'cpf',
        },
      },
      rg: {
        'ui:placeholder': '000000000',
      },
      birthDate: {
        'ui:placeholder': '00/00/0000',
      },
      motherName: {
        'ui:placeholder': 'Ex.: Ana da Silva',
      },
      isAdministrativeLife: {
        'ui:widget': 'radio',
      },
      hiredHealthPlan: {
        'ui:options': {
          colSpan: 2,
        },
        isActive: {
          'ui:widget': 'radio',
          'ui:options': {
            colSpan: 2,
          },
        },
        hiredHealthSubtitle: {
          'ui:widget': 'SubtitleWidget',
        },
        registrationNumber: {
          'ui:placeholder': 'Nº de matrícula',
        },
        activeHiredPlanInsurer: {
          'ui:placeholder': 'Selecione a operadora atual',
        },
        contractType: {
          'ui:placeholder': 'Selecione o tipo de contrato',
        },
        hiredAt: {
          'ui:placeholder': '00/00/0000',
        },
        modality: {
          'ui:placeholder': 'Selecione a modalidade',
        },
      },
    },
  }
}
