import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Button, Select, Skeleton } from 'design-system/components'
import { triggerToast } from 'design-system/triggers'

import { OptionsCustomerAssigment } from '@/App/clients/healthPlansOrder/dto'
import { GetOrderInformationBody } from '@/App/clients/healthPlansOrders/dtos'
import { getBrokersOptions } from '@/App/clients/healthPlansOrders/queries/getBrokersByManagerId'
import { getManagersOptions } from '@/App/clients/healthPlansOrders/queries/getManager'
import { useUpdateOrderBroker } from '@/App/clients/orderGateway/mutations/updateOrderBroker'

import {
  BrokerInfoContainer,
  ButtonContainer,
  Label,
  SelectContainer,
  StyledTitleResponsibleAdmin,
} from './style'

interface Props {
  orderData: GetOrderInformationBody
}

export const UpdateOrderBroker: React.FC<Props> = ({ orderData }) => {
  const [optionsCustomerAssigment, setOptionsCustomerAssigment] =
    useState<OptionsCustomerAssigment>()
  const [brokerName, setBrokerName] = useState(orderData?.broker?.name)
  const [brokerId, setbrokerId] = useState<string>()

  const getManagerQuery = useQuery(getManagersOptions())
  const getBrokerQuery = useQuery(
    getBrokersOptions({
      managerId: optionsCustomerAssigment?.managerId,
    }),
  )

  const orderId = orderData?.id

  const updateOrderBrokerMutation = useUpdateOrderBroker()

  useEffect(() => {
    if (optionsCustomerAssigment?.managerName === undefined) {
      getManagerQuery
    }
  }, [optionsCustomerAssigment?.managerName])

  useEffect(() => {
    if (getManagerQuery.data != null && getManagerQuery?.data?.managers != null) {
      const options = getManagerQuery?.data?.managers
        .map((manager) => ({
          label: manager.name,
          value: manager.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
      if (setOptionsCustomerAssigment) {
        setOptionsCustomerAssigment((oldState) => ({ ...oldState, managerOptions: options }))
      }
    }
  }, [getManagerQuery?.data])

  useEffect(() => {
    if (getBrokerQuery.data != null && getBrokerQuery?.data?.brokers != null) {
      const options = getBrokerQuery?.data?.brokers
        .map((broker) => ({
          label: broker.name,
          value: broker.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

      if (setOptionsCustomerAssigment) {
        setOptionsCustomerAssigment((oldState) => ({ ...oldState, brokerOptions: options }))
      }
    }
  }, [getBrokerQuery?.data])

  const setManager = (value: string) => {
    const manager = optionsCustomerAssigment?.managerOptions?.find(
      (manager) => manager.value === value,
    )
    if (setOptionsCustomerAssigment) {
      setOptionsCustomerAssigment({
        managerId: manager?.value,
        managerName: manager?.label,
        managerOptions: optionsCustomerAssigment?.managerOptions,
      })
    }
    setBrokerName(undefined)
  }

  const updateOrderBrokerRequest = async () => {
    const validateInsurer = orderData.quotation?.insurer && orderData.quotation.insurer.length >= 2
    if (orderId && orderData.quotation?.insurer !== null && validateInsurer) {
      await updateOrderBrokerMutation.mutateAsync({
        orderId,
        brokerId: brokerId,
        leadId: orderData?.quotation?.lead?.id,
        quotationId: orderData?.quotation?.id,
      })
    } else {
      triggerToast(
        false,
        'O campo Operadora é obrigatório para alterar o corretor. Por favor, preencha-o.',
      )
    }
  }

  return (
    <BrokerInfoContainer>
      <Label>
        <StyledTitleResponsibleAdmin>Gestor</StyledTitleResponsibleAdmin>
        {getManagerQuery.isFetching ? (
          <SelectContainer>
            <Skeleton type="input" />
          </SelectContainer>
        ) : (
          <SelectContainer>
            <Select
              defaultValue={orderData?.broker?.manager?.name}
              options={optionsCustomerAssigment?.managerOptions ?? []}
              onChange={(value) => {
                if (typeof value === 'string') {
                  setManager(value)
                }
              }}
            />
          </SelectContainer>
        )}
      </Label>
      <Label>
        <StyledTitleResponsibleAdmin>Corretor</StyledTitleResponsibleAdmin>
        {getBrokerQuery.isFetching ? (
          <SelectContainer>
            <Skeleton type="input" />
          </SelectContainer>
        ) : (
          <SelectContainer>
            <Select
              defaultValue={brokerName ?? 'Selecionar corretor'}
              options={optionsCustomerAssigment?.brokerOptions ?? []}
              onChange={(value) => setbrokerId(value as string)}
              showSearch
            />
          </SelectContainer>
        )}
      </Label>
      <ButtonContainer>
        <Button
          onClick={updateOrderBrokerRequest}
          height="40px"
          loading={updateOrderBrokerMutation.isPending}
        >
          Salvar
        </Button>
      </ButtonContainer>
    </BrokerInfoContainer>
  )
}
